import { useMemo, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Row, Column, Table, Button, Text } from 'components'
import HealthPlanAdd from './Modals/HealthPlanAdd'

import { getHealthplanList } from 'services'

import { EditIcon, TrashIcon } from 'assets'

const HealthPlan = ({ patientPlansList, setPatientPlansList }) => {
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(null)
  const [healthPlansList, setHealthPlansList] = useState([])

  const handleRemovePlan = id => setPatientPlansList(list => list.filter(l => l.operadora !== id))

  const columns = useMemo(
    () => [
      {
        header: 'Convênio',
        accessorKey: 'operadora',
        cell: ({ row: { original } }) =>
          healthPlansList?.find(item => Number(item.id) === Number(original.operadora))?.nome
      },
      {
        header: 'Número do cartão',
        accessorKey: 'numero_cartao'
      },
      {
        header: 'Data de validade',
        accessorKey: 'data_validade'
      },
      {
        header: 'Cartão nacional de saúde',
        accessorKey: 'codigo_cns'
      },
      {
        header: 'Ações',
        accessorKey: 'actions',
        sorting: false,
        cell: ({ row: { original } }) => (
          <Row gap='10px'>
            <Button minWidth='32px' width='22px' variant='contained' color='orange' onClick={() => setModal(original)}>
              <EditIcon width='22px' height='22px' fill='#fff' />
            </Button>
            <Button
              minWidth='32px'
              width='22px'
              variant='contained'
              color='danger'
              onClick={() => handleRemovePlan(original.operadora)}
            >
              <TrashIcon sx={{ ...btnStyle, color: '#fff' }} />
            </Button>
          </Row>
        )
      }
    ],
    [patientPlansList]
  )

  useEffect(() => {
    const handleGetHealthplans = async () => {
      try {
        setLoading(true)
        const { data } = await getHealthplanList()
        setHealthPlansList(data)
      } catch {
        toast.error('Não foi possível resgatar listagem de planos.')
      } finally {
        setLoading(false)
      }
    }
    handleGetHealthplans()
  }, [])

  return (
    <Column variant='start start' padding='15px 0'>
      <Row>
        <Text color='secondary' size='title'>
          Convênios cadastrados
        </Text>
      </Row>
      <Row height='100%'>
        {!loading && (
          <Table
            maxHeight='300px'
            data={patientPlansList}
            columns={columns}
            emptyMessage='Ops... Nenhum convênio cadastrado para este paciente.'
          />
        )}
      </Row>
      <Row variant='end-center' margin='20px 0 0'>
        <Button onClick={() => setModal(true)} variant='contained' disabled={loading}>
          Adicionar convênio
        </Button>
      </Row>
      {!loading && (
        <HealthPlanAdd
          open={!!modal}
          editData={modal}
          onClose={() => setModal(false)}
          healthPlansList={healthPlansList}
          setPatientPlansList={setPatientPlansList}
          patientPlansList={patientPlansList}
        />
      )}
    </Column>
  )
}

const btnStyle = {
  fontSize: '20px'
}

export default HealthPlan
