const WhatsappIcon = ({ width = 24, height = 24, fill = '#565656', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M16.875 7.0625C15.5938 5.75 13.8438 5 11.9688 5C8.15625 5 5.03125 8.125 5.03125 11.9375C5.03125 13.1875 5.375 14.375 5.96875 15.4062L5 19L8.65625 18.0625C9.6875 18.5938 10.8125 18.9062 11.9688 18.9062C15.8125 18.9062 19 15.7812 19 11.9688C19 10.0938 18.1875 8.375 16.875 7.0625ZM11.9688 17.7188C10.9375 17.7188 9.9375 17.4375 9.03125 16.9062L8.84375 16.7812L6.65625 17.375L7.25 15.25L7.09375 15.0312C6.53125 14.0938 6.21875 13.0312 6.21875 11.9375C6.21875 8.78125 8.8125 6.1875 12 6.1875C13.5312 6.1875 14.9688 6.78125 16.0625 7.875C17.1562 8.96875 17.8125 10.4062 17.8125 11.9688C17.8125 15.125 15.1562 17.7188 11.9688 17.7188ZM15.1562 13.4062C14.9688 13.3125 14.125 12.9062 13.9688 12.8438C13.8125 12.7812 13.6875 12.75 13.5625 12.9375C13.4688 13.0938 13.125 13.5 13.0312 13.625C12.9062 13.7188 12.8125 13.75 12.6562 13.6562C11.625 13.1562 10.9688 12.75 10.2812 11.5938C10.0938 11.2812 10.4688 11.3125 10.7812 10.6562C10.8438 10.5312 10.8125 10.4375 10.7812 10.3438C10.75 10.25 10.375 9.40625 10.25 9.0625C10.0938 8.71875 9.96875 8.75 9.84375 8.75C9.75 8.75 9.625 8.75 9.53125 8.75C9.40625 8.75 9.21875 8.78125 9.0625 8.96875C8.90625 9.15625 8.46875 9.5625 8.46875 10.4062C8.46875 11.2812 9.0625 12.0938 9.15625 12.2188C9.25 12.3125 10.375 14.0625 12.125 14.8125C13.2188 15.3125 13.6562 15.3438 14.2188 15.25C14.5312 15.2188 15.2188 14.8438 15.375 14.4375C15.5312 14.0312 15.5312 13.6875 15.4688 13.625C15.4375 13.5312 15.3125 13.5 15.1562 13.4062Z'
      fill={fill}
    />
  </svg>
)

export default WhatsappIcon
