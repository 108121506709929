import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const GlobalImports = () => {
  return (
    <>
      <ToastContainer autoClose={2000} closeOnClick />
      <Outlet />
    </>
  )
}

export default GlobalImports
