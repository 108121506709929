import { useCallback } from 'react'
import styled from 'styled-components'
import Popover from '@mui/material/Popover'
import { useReactToPrint } from 'react-to-print'
import * as XLSX from 'xlsx'

import { Column, Row } from 'components'

const OptionsPopover = ({
  id,
  open,
  state,
  onClose,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  },
  tableRef,
  tableName,
  data = [],
  columns = [],
  ...rest
}) => {
  const printContent = useCallback(() => {
    return tableRef.current
  }, [tableRef.current])

  const handleBrowserPrint = useReactToPrint({
    content: printContent,
    documentTitle: tableName,
    removeAfterPrint: true
  })

  const date = new Date().getDate()

  const columnsField = columns.map(({ field, headerName }) => ({ field: field, header: headerName }))
  const formatData = data.map(item =>
    columnsField.reduce((acc, { header, field }) => ({ ...acc, [header]: item[field] }), {})
  )

  const handleExport = () => {
    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.json_to_sheet(formatData)

    ws['!cols'] = [{ wch: 20 }]

    XLSX.utils.book_append_sheet(wb, ws, tableName)

    XLSX.writeFile(wb, `GestãoDS-${tableName}${date}.xlsx`)
  }

  return (
    <Popover
      id={open ? id : undefined}
      open={open}
      anchorEl={state}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...rest}
    >
      <Column variant='start-start'>
        <Row variant='start-center' padding='10px 5px' borderBottom='1px solid #e6e6e6'>
          <Label onClick={handleBrowserPrint}>Salvar / Imprimir</Label>
        </Row>
        <Row variant='start-center' padding='10px 5px' borderBottom='1px solid #e6e6e6'>
          <Label onClick={handleExport}>Exportar Excel</Label>
        </Row>
      </Column>
    </Popover>
  )
}

const Label = styled.label`
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
  padding: 3px;
`

export default OptionsPopover
