import { createContext, useState } from 'react'

const UserContext = createContext({})

export const UserProvider = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(null)
  const [eventsSchedule, setUpdateEventsSchedule] = useState({ configs: {}, events: [] })
  const [waitingRoom, setWaitingRoom] = useState([])
  const [medicTotals, setMedicTotals] = useState({})

  return (
    <UserContext.Provider
      value={{
        openSidebar,
        setOpenSidebar,
        eventsSchedule,
        setUpdateEventsSchedule,
        waitingRoom,
        setWaitingRoom,
        medicTotals,
        setMedicTotals
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
