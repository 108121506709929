import * as yup from 'yup'

export const loginSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required()
})

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().required()
})
