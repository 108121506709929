import { Spinner, Column, Row } from 'components'

import { primaryLogo } from 'assets'

const OverlayLoader = () => {
  return (
    <Column variant='between-center' width='100%' height='100vh'>
      <Row variant='start-center' height='50%'>
        <Spinner />
      </Row>
      <img src={primaryLogo} width='250px' alt='GestãoDS logo' />
    </Column>
  )
}

export default OverlayLoader
