import { Column } from 'components'
import MedicCard from './MedicCard'
import WaitingRoom from './WaitingRoom'

const Sidebar = ({
  loading,
  medicsRoomsList,
  handleGetEventsSchedule,
  handleGetMedicAndRooms,
  selectedSchedule,
  setSelectedSchedule
}) => {
  return (
    <Column width='300px'>
      <MedicCard
        loading={loading}
        medicsRoomsList={medicsRoomsList}
        handleGetEventsSchedule={handleGetEventsSchedule}
        handleGetMedicAndRooms={handleGetMedicAndRooms}
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={setSelectedSchedule}
      />
      <WaitingRoom />
    </Column>
  )
}

export default Sidebar
