const TasksIcon = ({ width = 24, height = 24, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M18.8056 11.5556H10.8056C10.5556 11.5556 10.3611 11.7778 10.3611 12V12.4444C10.3611 12.6944 10.5556 12.8889 10.8056 12.8889H18.8056C19.0278 12.8889 19.25 12.6944 19.25 12.4444V12C19.25 11.7778 19.0278 11.5556 18.8056 11.5556ZM18.8056 16H10.8056C10.5556 16 10.3611 16.2222 10.3611 16.4444V16.8889C10.3611 17.1389 10.5556 17.3333 10.8056 17.3333H18.8056C19.0278 17.3333 19.25 17.1389 19.25 16.8889V16.4444C19.25 16.2222 19.0278 16 18.8056 16ZM18.8056 7.11111H10.8056C10.5556 7.11111 10.3611 7.33333 10.3611 7.55556V8C10.3611 8.25 10.5556 8.44444 10.8056 8.44444H18.8056C19.0278 8.44444 19.25 8.25 19.25 8V7.55556C19.25 7.33333 19.0278 7.11111 18.8056 7.11111ZM6.80556 15.3333C6.08333 15.3333 5.47222 15.9444 5.47222 16.6667C5.47222 17.4167 6.08333 18 6.80556 18C7.52778 18 8.13889 17.4167 8.13889 16.6667C8.13889 15.9444 7.52778 15.3333 6.80556 15.3333ZM8.88889 10.5556C8.83333 10.5 8.75 10.4444 8.66667 10.4444C8.55556 10.4444 8.47222 10.5 8.41667 10.5556L6.63889 12.3056L6.02778 11.6944C5.97222 11.6389 5.88889 11.6111 5.77778 11.6111C5.69444 11.6111 5.61111 11.6389 5.55556 11.6944L5.11111 12.1389C5.05556 12.1944 5 12.2778 5 12.3611C5 12.4722 5.05556 12.5556 5.11111 12.6111L6.44444 13.9167C6.5 13.9722 6.58333 14 6.66667 14C6.77778 14 6.86111 13.9722 6.91667 13.9167L7.36111 13.4722L9.36111 11.5C9.41667 11.4444 9.44444 11.3611 9.44444 11.25C9.44444 11.1667 9.41667 11.0833 9.36111 11.0278L8.88889 10.5556ZM8.88889 6.11111C8.83333 6.05556 8.75 6 8.66667 6C8.55556 6 8.47222 6.05556 8.41667 6.11111L6.63889 7.86111L6.02778 7.25C5.97222 7.19444 5.88889 7.16667 5.77778 7.16667C5.69444 7.16667 5.61111 7.19444 5.55556 7.25L5.11111 7.69444C5.05556 7.75 5 7.83333 5 7.91667C5 8.02778 5.05556 8.11111 5.11111 8.16667L6.44444 9.47222C6.5 9.52778 6.58333 9.55556 6.66667 9.55556C6.77778 9.55556 6.86111 9.52778 6.91667 9.47222L9.36111 7.02778C9.41667 6.97222 9.47222 6.88889 9.47222 6.80556C9.47222 6.72222 9.41667 6.63889 9.36111 6.55556L8.88889 6.11111Z'
      fill={fill}
    />
  </svg>
)

export default TasksIcon
