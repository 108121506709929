import styled from 'styled-components'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import ptLocale from '@fullcalendar/core/locales/pt-br'

import { Column } from 'components'
import CalendarToolbar from './CalendarToolbar'

import { useUpdateUser } from 'hooks'

const Calendar = () => {
  const { eventsSchedule } = useUpdateUser()

  return (
    <Column width='100%' display='block'>
      <CalendarToolbar />
      <Container>
        <FullCalendar
          //   ref={calendarRef}
          schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
          nowIndicator
          eventOrder='id'
          height='auto'
          headerToolbar={false}
          allDaySlot={false}
          selectable={true}
          eventMinHeight={25}
          dayMaxEventRows={5}
          firstDay={1}
          locale={ptLocale}
          events={eventsSchedule}
          //   resources={medicAndRoomSelected.map(item => ({ id: item.id, title: item.nome }))}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
          //   eventTimeFormat={timeFormat}
          //   slotLabelFormat={timeFormat}
          slotDuration={'00:30:00'} // pedir para back converter?
          //   slotLabelInterval={configs?.tempo_agenda}
          //   slotMinTime={configs?.hora_menor || '23:59'}
          //   slotMaxTime={configs?.hora_maior || '00:00'}
          //   eventContent={EventCard}
        />
      </Container>
      {/* <FullCalendar
            hiddenDays={!multiAgenda && hiddenDays}
            datesSet={(date) => {
              updateAgendaStore({ agendaDate: date });
              handleGetSchedules(date, multiAgenda);
            }}
            eventClick={(info) => handlerAtendimento(info.event)}
            eventDrop={(info) => handleChangeTime(info.event)}
            eventResize={(info) => handleChangeTime(info.event)}
            dateClick={(info) =>
              multiAgenda && (currentView === "timeGridWeek" || currentView === "dayGridMonth")
                ? setSelectPatientModal(info)
                : handleOpenModalAgendamento(info)
            }
          /> */}
    </Column>
  )
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  color: #656565;
  font-weight: 400;
  & .fc-event-main {
    overflow: hidden !important;
  }
  & .fc-timegrid-event {
    width: 100%;
  }
  & .fc-timegrid-col {
    padding-right: 6px !important;
  }
  & .fc-event-main {
    padding-left: 6px !important;
  }
  & .fc-timegrid-slot {
    height: 50px !important;
  }
  & .fc-scrollgrid-section-header .fc-scroller {
    overflow: hidden !important;
  }
  & .fc-daygrid-day {
    overflow: hidden;
    padding: 0 10px;
  }
  & .fc-daygrid-event {
    color: #212121 !important;
  }
  & .fc-day-today {
    background-color: rgba(114, 102, 186, 1);
    color: #fff;
  }
  & .fc-day-today .fc-daygrid-day-number {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
  }
  & .fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(114, 102, 186, 0.1);
  }
  & .fc-daygrid-day-top {
    color: #b2b2b2;
  }
  & .fc-theme-standard .fc-popover-header {
    background: rgba(114, 102, 186, 1);
    color: #fff;
  }
  & .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(114, 102, 186, 0.1);
  }
  & .fc .fc-bg-event {
    opacity: 0.35 !important;
  }
  & .fc .fc-timegrid-event {
    border-left: 5px solid;
  }
  & .fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {
    width: 100% !important;
  }
  & .fc table {
    width: ${({ view }) => (view ? '1800px' : '100%')};
    overflow: auto;
  }
  & .fc-timegrid-event-harness:hover {
    z-index: 999 !important;
  }
  & .fc-timegrid-event-harness:hover .schedule-resize-icons {
    display: flex;
  }
  & .fc-daygrid-dot-event {
    width: 100%;
  }
  & .fc-timegrid-event .fc-event-resizer {
    color: #212121;
    text-align: center;
  }
  & .fc-timegrid-event:hover .fc-event-resizer:after {
    content: '=';
    vertical-align: top;
    line-height: 0px;
  }
`

const EventContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  width: 100%;
`
const EventContainer = styled.div`
  position: relative;
  margin: 2px 0;
  line-height: 12px;
  font-size: 11.4px;
  font-weight: 400;
  height: 100%;
  width: 100%;
`
const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color = '#212121' }) => color};
  border-radius: 100%;
`
const EventTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`

export default Calendar
