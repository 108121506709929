import { useFormContext } from 'react-hook-form'

import { Row, Column, Input } from 'components'

import { removeMask } from 'helpers'

import { getCep } from 'providers'

const Location = () => {
  const { register, setValue, setFocus } = useFormContext()

  const handleGetCep = async value => {
    const {
      data: { bairro, localidade, logradouro, uf }
    } = await getCep(removeMask(value))
    setValue('bairro', bairro)
    setValue('cidade', localidade)
    setValue('endereco', logradouro)
    setValue('uf', uf)
    setFocus('numero', { shouldSelect: true })
  }

  return (
    <Column variant='start start' padding='15px 0'>
      <Row gap='15px' flexWrap='wrap'>
        <Input mask='99999-999' label='CEP' onLeave={event => handleGetCep(event.target.value)} {...register('cep')} />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Input label='UF' {...register('uf')} />
        <Input label='Cidade' {...register('cidade')} />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Input label='Bairro' {...register('bairro')} />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Input label='Endereço' {...register('endereco')} />
        <Input label='Número' {...register('numero')} />
        <Input label='Complemento' {...register('complemento')} />
      </Row>
    </Column>
  )
}
export default Location
