import styled from 'styled-components'
import { Row, Column, Text } from 'components'

import { ArrowNavigate } from 'assets'

const Pagination = ({ page, setPage, totalPages, next, previous, loading, count }) => (
  <Row width='100%' variant='end-center' padding='25px'>
    <Column>
      <Row width='100%' variant='between-center' m='10px 0'>
        <NavigateButton onClick={() => setPage(page - 1)} disabled={page === 1 || loading || !previous}>
          <ArrowNavigate direction='left' width='12' height='16' fill='#743A8E' />
        </NavigateButton>
        <NavigateButton onClick={() => setPage(page + 1)} disabled={page === totalPages || loading || !next}>
          <ArrowNavigate fill='#743A8E' width='12' height='16' direction='right' />
        </NavigateButton>
      </Row>
      <Column>
        <Text size='paragraph_2' color='neutral.500'>{`Página ${page} de ${totalPages}`}</Text>
        <Text size='paragraph_2' color='neutral.500'>{`Total de registros: ${count}`}</Text>
      </Column>
    </Column>
  </Row>
)

const NavigateButton = styled.button`
  width: 25px;
  height: 25px;
  border: none;
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`

export default Pagination
