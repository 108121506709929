export const palette = {
  primary: {
    main: '#743A8E',
    light: '#EEE6F1',
    dark: '#220430',
    50: '#EEE6F1',
    100: '#C9B3D3',
    200: '#AF8EBE',
    300: '#8A5AA1',
    400: '#743A8E',
    500: '#510972',
    600: '#4A0868',
    700: '#3A0651',
    800: '#2D053F',
    900: '#220430'
  },
  secondary: {
    main: '#136CDC',
    light: '#4289E3',
    dark: '#0D4D9C',
    50: '#E7F0FC',
    100: '#B6D1F4',
    200: '#92BBEF',
    300: '#619DE8',
    400: '#4289E3',
    500: '#136CDC',
    600: '#1161C8',
    700: '#0D4D9C',
    800: '#0A3B79',
    900: '#082D5C'
  },
  complementary: {
    main: '#FC8458',
    light: '#FD9D79',
    dark: '#E57850',
    50: '#FFF3EE',
    100: '#FED9CB',
    200: '#FEC6B2',
    300: '#FDAD8F',
    400: '#FD9D79',
    500: '#FC8458',
    600: '#E57850',
    700: '#B35E3E',
    800: '#8B4930',
    900: '#6A3725'
  },
  neutral: {
    main: '#FBFBFB',
    light: '#FCFCFC',
    dark: '#A9A9A9',
    white: '#FFFFFF',
    50: '#F3F3F3',
    100: '#BEBEBE',
    200: '#A9A9A9',
    300: '#EEEEEE',
    400: '#565656',
    500: '#656565',
    700: '#E4E4E4',
    900: '#2C2C2C'
  },
  success: {
    main: '#1C8735',
    light: '#EBFAEE',
    dark: '#146026',
    50: '#EBFAEE',
    100: '#E8F3EB',
    200: '#B9DAC0',
    300: '#97C8A2',
    400: '#499F5D',
    500: '#1C8735',
    600: '#197B30',
    700: '#146026',
    800: '#0F4A1D',
    900: '#0C3916'
  },
  danger: {
    main: '#D82929',
    light: '#FBEAEA',
    dark: '#991D1D',
    50: '#FBEAEA',
    100: '#F3BDBD',
    200: '#ED9D9D',
    300: '#E57070',
    400: '#E05454',
    500: '#D82929',
    600: '#C52525',
    700: '#991D1D',
    800: '#771717',
    900: '#5B1111'
  },
  success: {
    main: '#00A843',
    light: '#19FF75',
    dark: '#00A843',
    50: '#19FF75',
    500: '#00A843',
    700: '#00A843'
  },
  orange: {
    main: '#ff902b',
    light: '#fda98a',
    dark: '#fc723f'
  }
}

export const fontSize = {
  h1: '96px',
  h2: '60px',
  h3: '48px',
  h4: '34px',
  h5: '24px',
  h6: '20px',
  paragraph: '16px',
  paragraph_2: '14px',
  caption: '12px',
  helper: '9px'
}

export const flexVariants = {
  center: { justifyContent: 'center', alignItems: 'center' },
  'center-start': { justifyContent: 'center', alignItems: 'flex-start' },
  'center-end': { justifyContent: 'center', alignItems: 'flex-end' },
  'start-start': { justifyContent: 'flex-start', alignItems: 'flex-start' },
  'start-center': { justifyContent: 'flex-start', alignItems: 'center' },
  'start-end': { justifyContent: 'flex-start', alignItems: 'flex-end' },
  'end-start': { justifyContent: 'flex-end', alignItems: 'flex-start' },
  'end-end': { justifyContent: 'flex-end', alignItems: 'flex-end' },
  'end-center': { justifyContent: 'flex-end', alignItems: 'center' },
  'between-start': { justifyContent: 'space-between', alignItems: 'flex-start' },
  'between-center': { justifyContent: 'space-between', alignItems: 'center' },
  'between-end': { justifyContent: 'space-between', alignItems: 'flex-end' },
  'around-start': { justifyContent: 'space-around', alignItems: 'flex-start' },
  'around-center': { justifyContent: 'space-around', alignItems: 'flex-center' },
  'around-end': { justifyContent: 'space-around', alignItems: 'flex-end' },
  'start-between': { justifyContent: 'flex-start', alignItems: 'space-between' },
  'center-between': { justifyContent: 'center', alignItems: 'space-between' },
  'end-between': { justifyContent: 'flex-end', alignItems: 'space-between' },
  'start-around': { justifyContent: 'flex-start', alignItems: 'space-around' },
  'center-around': { justifyContent: 'center', alignItems: 'space-around' },
  'end-around': { justifyContent: 'flex-end', alignItems: 'space-around' }
}

export const bloodTypeOptions = [
  { value: null, label: 'Não informado' },
  { value: 'O+', label: 'O+' },
  { value: 'A+', label: 'A+' },
  { value: 'B+', label: 'B+' },
  { value: 'AB+', label: 'AB+' },
  { value: 'O-', label: 'O-' },
  { value: 'A-', label: 'A-' },
  { value: 'B-', label: 'B-' },
  { value: 'AB-', label: 'AB-' }
]

export const genderOptions = [
  { value: 'F', label: 'Feminino' },
  { value: 'M', label: 'Masculino' },
  { value: 'O', label: 'Outro' }
]

export const civilStateOptions = [
  { value: 'Solteiro(a)', label: 'Solteiro(a)' },
  { value: 'Casado(a)', label: 'Casado(a)' },
  { value: 'Divorciado(a)', label: 'Divorciado(a)' },
  { value: 'Separado(a)', label: 'Separado(a)' },
  { value: 'Viúvo(a)', label: 'Viúvo(a)' },
  { value: 'União Estável', label: 'União Estável' }
]

export const calendarViewDict = {
  resourceTimeGridDay: 'Dia',
  timeGridDay: 'Dia',
  timeGridWeek: 'Semana',
  dayGridMonth: 'Mês'
}

export const optionsScheduleView = active => [
  { label: 'Dia', value: active ? 'resourceTimeGridDay' : 'timeGridDay' },
  { label: 'Semana', value: 'timeGridWeek' },
  { label: 'Mês', value: 'dayGridMonth' }
]

export const statusColorsDict = {
  'Não confirmado': { label: 'Agendado', value: 'nao_confirmado', bgColor: '#5d9cec', color: '#fff' },
  'Em atendimento': { label: 'Em atendimento', value: 'em_atendimento', bgColor: '#4bc24b', color: '#fff' },
  'Em espera': { label: 'Em espera', value: 'em_espera', bgColor: '#4bc24b', color: '#fff' },
  Confirmado: { label: 'Confirmado', value: 'confirmado', bgColor: '#f28f2d', color: '#fff' },
  Cancelado: { label: 'Cancelado', value: 'cancelado', bgColor: '#f05050', color: '#fff' },
  Finalizado: { label: 'Finalizado', value: 'finalizado', bgColor: '#212121', color: '#fff' }
}
