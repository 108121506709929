const CalendarAlt = ({ width = 24, height = 24, fill = '#656565', active = false, ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 6C18.3125 6 19 6.6875 19 7.5V8.5H5V7.5C5 6.6875 5.65625 6 6.5 6H8V4.375C8 4.1875 8.15625 4 8.375 4H9.625C9.8125 4 10 4.1875 10 4.375V6H14V4.375C14 4.1875 14.1562 4 14.375 4H15.625C15.8125 4 16 4.1875 16 4.375V6H17.5ZM5 9V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V9H5ZM10 12.625C10 12.8438 9.8125 13 9.625 13H8.375C8.15625 13 8 12.8438 8 12.625V11.375C8 11.1875 8.15625 11 8.375 11H9.625C9.8125 11 10 11.1875 10 11.375V12.625ZM13 11.375V12.625C13 12.8438 12.8125 13 12.625 13H11.375C11.1562 13 11 12.8438 11 12.625V11.375C11 11.1875 11.1562 11 11.375 11H12.625C12.8125 11 13 11.1875 13 11.375ZM16 12.625V11.375C16 11.1875 15.8125 11 15.625 11H14.375C14.1562 11 14 11.1875 14 11.375V12.625C14 12.8438 14.1562 13 14.375 13H15.625C15.8125 13 16 12.8438 16 12.625ZM13 14.375V15.625C13 15.8438 12.8125 16 12.625 16H11.375C11.1562 16 11 15.8438 11 15.625V14.375C11 14.1875 11.1562 14 11.375 14H12.625C12.8125 14 13 14.1875 13 14.375ZM10 15.625V14.375C10 14.1875 9.8125 14 9.625 14H8.375C8.15625 14 8 14.1875 8 14.375V15.625C8 15.8438 8.15625 16 8.375 16H9.625C9.8125 16 10 15.8438 10 15.625ZM16 14.375V15.625C16 15.8438 15.8125 16 15.625 16H14.375C14.1562 16 14 15.8438 14 15.625V14.375C14 14.1875 14.1562 14 14.375 14H15.625C15.8125 14 16 14.1875 16 14.375Z'
      fill={active ? '#743A8E' : fill ? fill : '#2C2C2C'}
    />
  </svg>
)

export default CalendarAlt
