import { forwardRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'

import { Input, Text } from 'components'

import { GestaoDsIcon } from 'assets'

const InputIcon = forwardRef(
  ({ maxWidth = '280px', icon = <GestaoDsIcon active />, iconProps, register, label, ...rest }, _ref) => {
    const inputId = uuidv4()

    return (
      <Base maxWidth={maxWidth}>
        <Input
          id={inputId}
          margin='0'
          maxWidth={maxWidth}
          padding='7px 38px 7px 8px'
          label={label}
          {...rest}
          {...register}
        />
        <Text component='label' htmlFor={inputId}>
          <Icon label={label} {...iconProps}>
            {icon}
          </Icon>
        </Text>
      </Base>
    )
  }
)

const Base = styled.div`
  position: relative;
  margin: 10px 0;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
`

const Icon = styled.div`
  position: absolute;
  top: ${({ label }) => (label ? '25px' : '9px')};
  right: 10px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

export default InputIcon
