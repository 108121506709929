import { createRef, useState } from 'react'
import styled from 'styled-components'

import { Column } from 'components'

import ArrowDown from './arrow_down'
import PlayIcon from './PlayIcon'
import PauseIcon from './PauseIcon'

import { formatProgress, formatTimeProgress } from 'helpers'

const Progress = ({ progress }) => {
  const { timeline, currentTime } = progress
  return (
    <Base>
      <Line progress={timeline}>
        <Text>{currentTime || '00:00'}</Text>
      </Line>
    </Base>
  )
}

const MarketingCard = () => {
  const [status, setStatus] = useState(false)
  const [progress, setProgress] = useState({ timeline: 0, currentTime: 0 })

  const videoRef = createRef()

  const handleStart = () => {
    if (videoRef.current) {
      const player = videoRef.current
      status ? player.pause() : player.play()
      setStatus(!status)
    }
  }

  const handleGetTimeLine = () => {
    if (videoRef) {
      setProgress({
        timeline: formatProgress(videoRef.current.duration, videoRef.current.currentTime),
        currentTime: formatTimeProgress(videoRef.current.currentTime)
      })
    }
  }

  const handleClick = () =>
    window.open(
      'https://api.whatsapp.com/send?phone=555191079550&text=Ol%C3%A1!%20Quero%20conhecer%20mais%20sobre%20o%20DS%20Content'
    )

  return (
    <Column variant='center'>
      <Container>
        {status ? (
          <ContainerIcon status={status}>
            <PlayIcon />
          </ContainerIcon>
        ) : (
          <ContainerIcon status={status}>
            <PauseIcon />
          </ContainerIcon>
        )}
        <Video
          ref={videoRef}
          src='https://intranet-gestaods.s3.amazonaws.com/media/dscontent_video.mp4'
          onTimeUpdate={e => handleGetTimeLine()}
          onClick={() => handleStart()}
          loop
        ></Video>
        <Progress progress={progress} />
      </Container>
      <Column m='15px 0'>
        <ArrowDown />
      </Column>
      <CustomButton onClick={() => handleClick()}>
        Quero esse produto!
        <EffectContainer />
      </CustomButton>
    </Column>
  )
}

const Text = styled.p`
  position: absolute;
  top: 5px;
  right: -14px;
  color: #510972;
  font-size: 12px;
  transform: translateY(5px);
`

const Base = styled.div`
  position: relative;
  width: 90%;
  height: 6px;
  margin: 10px 0;
  background-color: rgba(79, 9, 111, 0.5);
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  @keyframes marketingFadeinOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes marketingFadeoutIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`
const ContainerIcon = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 45%;
  left: 45%;
  transform: translateX(-25px) translateY(-25px);
  background-color: #bfbfbf;
  border-radius: 10px;
  padding: 10px;
  opacity: 0;
  animation: ${({ status }) => (status ? 'marketingFadeinOut 1s ease' : 'marketingFadeoutIn 1s ease')};
`

const Line = styled.div`
  position: absolute;
  width: ${({ progress = 0 }) => `${progress || 0}%`};
  max-width: 100%;
  height: 6px;
  background-color: #510972;
  &::before {
    content: '';
    position: absolute;
    right: -4px;
    transform: translateY(-4px);
    border-radius: 100%;
    width: 13px;
    height: 13px;
    background-color: #510972;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-height: 400px;
  border-radius: 25px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    min-height: 350px;
  }
`

const CustomButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  width: 100%;
  padding: 10px;
  border: 2px solid #510972;
  border-radius: 25px;
  color: #510972;
  box-shadow: rgb(81 9 114 / 85%) 0px 4px 7px;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover div {
    width: 100%;
  }
`

const EffectContainer = styled.div`
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  border-radius: 25px;
  background-color: #510972;
  opacity: 0.1;
  transition: all 0.5s ease;
`

const ProgressBar = styled.div`
  position: relative;
  width: 90%;
  height: 6px;
  margin: 10px 0;
  background-color: #4f096f80;
`
const CurrentTime = styled.p`
  position: absolute;
  width: 10%;
  max-width: 100%;
  height: 6px;
  background-color: #510972;
  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: -2px;
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
  }
`

export default MarketingCard
