import { format } from 'date-fns'
const regexCpf = /(\d{3})(\d{3})(\d{3})(\d{2})/g
const regexCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g
const regexData = /(\d{2})(\d{2})(\d{4})/g
const regexDateTime = /(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})/g
const regexTime = /(\d{2})(\d{2})/g
const regexCep = /(\d{5})(\d{2})/g
const regexResidencialPhone = /(\d{2})(\d{4})(\d{4})/g
const regexPhone = /(\d{2})(\d{5})(\d{4})/g
const regexInternationalPhone = /(\d{2})/g

export const currencyConverter = value =>
  value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'Invalid value ex. R$ 152,04'

export const addCpfMask = value => String(value).replace(regexCpf, '$1.$2.$3-$4')

export const addCnpjMask = value => String(value).replace(regexCnpj, '$1.$2.$3/$4-$5')

export const addDateMask = value => String(value).replace(regexData, '$1/$2/$3')

export const addDateTimeMask = value => String(value).replace(regexDateTime, '$1/$2/$3 - $4:$5')

export const addTimeMask = value => String(value).replace(regexTime, '$1:$2')

export const addCepMask = value => String(value).replace(regexCep, '$1-$2')

export const addPhoneMask = value =>
  String(value).length < 11
    ? String(value).replace(regexResidencialPhone, '($1) $2-$3')
    : String(value).replace(regexPhone, '($1) $2-$3')

export const addInternationalPhoneMask = value => {
  const format = String(value).slice(0, 2).replace(regexInternationalPhone, '+$1')
  const concat = format.concat(String(value).slice(2))
  return concat || 'invalid value'
}

export const removeMask = value => String(value).replace(/[^\d]/g, '')

export const formatProgress = (duration, time) => (time / duration) * 100

export const formatTimeProgress = value => {
  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value % 3600) / 60)
  const seconds = Math.floor(value % 60)

  return `${hours > 0 ? `${hours}:` : ''}${minutes > 9 ? minutes : `0${minutes}`}:${
    seconds > 9 ? seconds : `0${seconds}`
  }`
}

export const formatIntervalTime = time => {
  const m = time % 60
  const h = (time - m) / 60
  const HHMM = `${h < 10 ? '0' : ''}${h.toString()}:${m < 10 ? '0' : ''}${m.toString()}:00`
  return HHMM
}

export const convertToDate = (date, formatter) => new Date(date?.split(formatter).reverse())

export const convertToDateHour = (date, formatter) =>
  new Date(date.split(' ')[0].split(formatter).reverse().concat(date?.split(' ')[1]))

export const convertDate = (date, formatter = '/', divisor = '-') => {
  if (!date) return date

  const split = date.split(formatter)

  return `${split[2]}${divisor}${split[1]}${divisor}${split[0]}`
}

export const formatDate = date => format(date, 'dd/MM/yyyy')

export const hexToRgb = (value, opacity = 1) => {
  if (value.length !== 7) {
    return false
  }

  const hex = value.split('#')[1]

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})})`
}

export const darkHex = (value, opacity = 1) => {
  if (value.length !== 7) {
    return false
  }

  const hex = value.split('#')[1]

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r - 50},${g - 50},${b - 50},${opacity})`
}

export const clearObject = (object, skipArray = []) => {
  Object.keys(object).forEach(key => {
    if (!skipArray.includes(key) && (object[key] === null || object[key] === undefined || object[key] === '')) {
      delete object[key]
    }
  })
  return object
}
