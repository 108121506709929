import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Popover from '@mui/material/Popover'

import { Input, Checkbox, Column, Button } from 'components'

const FilterPopover = ({
  id,
  open,
  state,
  onClose,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  },
  filters,
  setSearchFilters,
  ...rest
}) => {
  const [filterGroup, setFilterGroup] = useState({})
  const selectFilterInput = {
    text: props => <Input {...props} margin='0' />,
    checkbox: props => <Checkbox {...props} color='secondary' />
  }

  const selectFilterValue = {
    text: 'value',
    checkbox: 'checked'
  }

  const handleSubmitSearch = filterObject => {
    setSearchFilters(filterObject)
    onClose()
  }

  return (
    <Popover
      id={open ? id : undefined}
      open={open}
      anchorEl={state}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...rest}
    >
      <Column variant='center-start' padding='15px'>
        {filters.map(elem => (
          <Column margin='10px 0'>
            {selectFilterInput[elem.type]({
              ...elem,
              ...(elem.type === 'text' && { value: filterGroup[elem.key] || '' }),
              ...(elem.type === 'checkbox' && { checked: filterGroup[elem.key] || '' }),
              id: uuidv4(),
              onChange: e => setFilterGroup({ ...filterGroup, [elem.key]: e.target[selectFilterValue[elem.type]] })
            })}
          </Column>
        ))}
        <Column margin='15px 0 0' width='100%'>
          <Button width='100%' variant='contained' color='secondary' onClick={() => handleSubmitSearch(filterGroup)}>
            Filtrar
          </Button>
        </Column>
      </Column>
    </Popover>
  )
}

export default FilterPopover
