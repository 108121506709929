const Medic = ({ width = 32, height = 32, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M8.50016 8.00008C9.97308 8.00008 11.1668 6.80633 11.1668 5.33341C11.1668 3.8605 9.97308 2.66675 8.50016 2.66675C7.02725 2.66675 5.8335 3.8605 5.8335 5.33341C5.8335 6.80633 7.02725 8.00008 8.50016 8.00008ZM6.00016 11.5001C6.00016 11.7772 6.22308 12.0001 6.50016 12.0001C6.77725 12.0001 7.00016 11.7772 7.00016 11.5001C7.00016 11.223 6.77725 11.0001 6.50016 11.0001C6.22308 11.0001 6.00016 11.223 6.00016 11.5001ZM10.5002 8.67925V9.70008C11.2606 9.85425 11.8335 10.5292 11.8335 11.3334V12.2022C11.8335 12.3605 11.721 12.498 11.5647 12.5292L10.8939 12.6626C10.8043 12.6813 10.7168 12.623 10.6981 12.5313L10.6335 12.2042C10.6147 12.1147 10.6731 12.0251 10.7647 12.0084L11.1668 11.9272V11.3334C11.1668 10.0251 9.16683 9.97716 9.16683 11.373V11.9292L9.56891 12.0105C9.65849 12.0292 9.71683 12.1167 9.70016 12.2063L9.63558 12.5334C9.61683 12.623 9.52933 12.6813 9.43975 12.6647L8.78975 12.5772C8.62516 12.5542 8.50225 12.4147 8.50225 12.2459V11.3334C8.50225 10.5292 9.07516 9.85633 9.83558 9.70008V8.75841C9.78975 8.773 9.74391 8.78133 9.69808 8.798C9.32308 8.92925 8.921 9.00216 8.50225 9.00216C8.0835 9.00216 7.68141 8.92925 7.30641 8.798C7.15225 8.74383 6.996 8.7105 6.83558 8.68966V10.3897C7.31683 10.5334 7.66891 10.9751 7.66891 11.5022C7.66891 12.1459 7.146 12.6688 6.50225 12.6688C5.8585 12.6688 5.33558 12.1459 5.33558 11.5022C5.33558 10.9751 5.68766 10.5334 6.16891 10.3897V8.71466C4.84391 8.93758 3.8335 10.0792 3.8335 11.4667V12.4001C3.8335 12.9147 4.25225 13.3334 4.76683 13.3334H12.2335C12.7481 13.3334 13.1668 12.9147 13.1668 12.4001V11.4667C13.1668 9.96675 11.9835 8.75216 10.5002 8.67925Z'
      fill={fill}
    />
  </svg>
)

export default Medic
