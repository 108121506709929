import { Text, Column } from 'components'

import { under_construction } from 'assets'

const UnderConstruction = () => {
  return (
    <Column variant='center' marginTop='50px' height='500px'>
      <img
        src={under_construction}
        srcSet={`${under_construction}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
        alt='Em construção'
      />
      <Text mt='40px' size='h5' color='neutral.400'>
        Em construção :)
      </Text>
    </Column>
  )
}

export default UnderConstruction
