import { useEffect, useRef } from 'react'

const useClickout = (initState, callbackUpdate, stateUpdate) => {
  const triggerRef = useRef(null)
  const elementRef = useRef(null)

  const handleClickOutside = event => {
    if (stateUpdate && elementRef.current && elementRef.current.contains(event.target)) {
      return callbackUpdate(!initState)
    }

    if (elementRef.current && !elementRef.current.contains(event.target)) {
      return callbackUpdate(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return {
    triggerRef,
    elementRef
  }
}

export default useClickout
