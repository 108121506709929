const HamburgerOpen = ({ width = 24, height = 24, fill = '#565656', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M19.575 7.08511C19.7875 7.08511 20 6.89894 20 6.6383V5.44681C20 5.2234 19.7875 5 19.575 5H4.5577C4.30977 5 4.13268 5.2234 4.13268 5.44681V6.6383C4.13268 6.89894 4.30977 7.08511 4.5577 7.08511H19.575ZM19.575 19C19.7875 19 20 18.8138 20 18.5532V17.3617C20 17.1383 19.7875 16.9149 19.575 16.9149H4.5577C4.30977 16.9149 4.13268 17.1383 4.13268 17.3617V18.5532C4.13268 18.8138 4.30977 19 4.5577 19H19.575Z'
      fill={fill}
    />
    <path
      d='M19.9997 12.612C19.9997 12.8727 19.7872 13.0588 19.5747 13.0588H8.38303L8.38299 14.5319C8.38299 14.9415 7.92256 15.1277 7.63921 14.8298L4.13282 12.2979C3.95573 12.1489 3.95573 11.8511 4.13282 11.6649L7.63921 8.83511C7.92256 8.57447 8.38299 8.76064 8.38299 9.17021L8.38303 10.9737H19.5747C19.7872 10.9737 19.9997 11.1971 19.9997 11.4205V12.612Z'
      fill={fill}
    />
  </svg>
)

export default HamburgerOpen
