import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'

import { InputIcon, Button, Spinner, Column, Text, AnimatedContainer } from 'components'

import { resetPassword } from 'services'

import { resetPasswordSchema } from 'schemas'

import { gestaoDsLogo } from 'assets'

const FormResetPassword = ({ setOption }) => {
  const [loading, setLoading] = useState(false)
  const [statusRequest, setStatusRequest] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(resetPasswordSchema) })

  const onSubmit = async data => {
    try {
      setLoading(true)
      setStatusRequest(false)
      const response = await resetPassword(data)

      console.log('res', response)
      setStatusRequest({ status: 'success', message: 'Enviamos um email com instruções para resgatar sua senha.' })
    } catch (err) {
      const { data, status } = err?.response
      if (status === 400 && data.email) {
        setStatusRequest({ status: 'error', message: 'Não há usuário ativo associado a este endereço de e-mail.' })
        return toast.error('Não há usuário ativo associado a este endereço de e-mail.')
      }
      toast.error('Não foi possível resgatar email.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <AnimatedContainer>
      <Column variant='center' width='100%'>
        <Column maxWidth='320px'>
          <Image src={gestaoDsLogo} alt='GestãoDS' />
          <Text lineHeight='28px' size='h5' color='neutral.500' fontWeight='bold'>
            Informe seu email de cadastro
          </Text>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputIcon
              maxWidth='100%'
              type='email'
              label='Email'
              autoComplete='false'
              placeholder='Informe seu email'
              error={!!errors?.email}
              errorMessage='Informe seu email'
              icon={<AlternateEmailIcon fontSize='24' color={!!errors.email ? 'danger' : 'primary'} />}
              register={register('email')}
            />
            <Column width='100%' variant='end-end'>
              <Text size='caption' onClick={() => setOption(true)} color='primary' cursorPointer>
                Fazer login
              </Text>
            </Column>
            {statusRequest && (
              <Column mt='20px'>
                <Text
                  size='caption'
                  fontWeight='bold'
                  color={statusRequest.status !== 'success' ? 'danger.main' : 'success.main'}
                >
                  {statusRequest.message}
                </Text>
              </Column>
            )}
            <Button
              maxWidth='100%'
              width='100%'
              type='submit'
              variant='contained'
              sx={{ margin: '20px 0 0' }}
              disabled={loading}
              {...{ startIcon: loading ? <Spinner variant='loader' /> : '' }}
            >
              {loading ? 'Carregando' : 'Resgatar'}
            </Button>
          </Form>
        </Column>
      </Column>
    </AnimatedContainer>
  )
}

const Image = styled.img`
  width: 180px;
  margin-bottom: 25px;
`
const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default FormResetPassword
