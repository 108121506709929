import { Text, Row, Column } from 'components'
import ButtonAttendance from './ButtonAttendance'

import { HeartBeatIcon, StethoscopeIcon, BriefMedicalIcon, MedicIcon, SyringeIcon } from 'assets'

const Attendance = ({ totals }) => {
  return (
    <Column variant='center-start' gap='8px'>
      <Text size='caption' color='white'>
        Em andamento:
      </Text>
      <Row variant='start-center' gap='8px' flexDirection={{ mobile: 'column', tablet: 'row', desktop: 'row' }}>
        <ButtonAttendance
          content={totals?.consultas?.total}
          label='Consultas'
          icon={<StethoscopeIcon width='12px' height='12px' fill='#743A8E' />}
        />
        <ButtonAttendance
          content={totals?.exames?.total}
          label='Exames'
          icon={<HeartBeatIcon width='16' height='16' fill='#743A8E' />}
        />
        <ButtonAttendance
          content={totals?.cirurgias?.total}
          label='Cirurgias'
          icon={<BriefMedicalIcon width='16' height='16' fill='#743A8E' />}
        />
        <ButtonAttendance
          content={totals?.procedimentos?.total}
          label='Procedimentos'
          icon={<MedicIcon width='16' height='16' fill='#743A8E' />}
        />
        <ButtonAttendance
          content={totals?.vacinas?.total}
          label='Vacinas'
          icon={<SyringeIcon width='16' height='16' fill='#743A8E' />}
        />
      </Row>
    </Column>
  )
}

export default Attendance
