import { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, Tab, Tabs } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { Button, Row, Spinner, Text } from 'components'
import BasicInfo from './BasicInfo'
import Contact from './Contact'
import Location from './Location'
import HealthPlan from './HealthPlan'

import { removeMask, convertDate, clearObject } from 'helpers'

import { patientSchema } from 'schemas'

import { postPatient, getPatient, putPatient, updatePatientImage } from 'services'

const NewPatientModal = ({ open, handleClose, patient }) => {
  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [patientPlansList, setPatientPlansList] = useState([])

  const methods = useForm({
    resolver: yupResolver(patientSchema)
  })

  const onSubmit = async data => {
    try {
      setLoading(true)
      const formatData = {
        ...data,
        cpf: removeMask(data.cpf),
        obito: !!removeMask(data.data_obito),
        convenios_paciente: patientPlansList,
        data_obito: data.data_obito ? convertDate(data.data_obito, '/', '-') : null,
        data_nascimento: data.data_nascimento ? convertDate(data.data_nascimento, '/', '-') : null
      }

      clearObject(formatData)

      const response = patient ? await putPatient(patient, formatData) : await postPatient(formatData)

      if (response?.data?.id && data?.imagem) {
        await updatePatientImage(response?.data?.id, data.imagem)
      }

      console.log('res', response)

      toast.success('Paciente cadastrado com sucesso!')
      methods.reset({})
      handleClose()
    } catch (e) {
      console.log('dsa', e)
      toast.error('Não foi possível salvar paciente!')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (patient) {
      const handleGetPatient = async () => {
        try {
          setLoading(true)
          const { data } = await getPatient(patient)
          Object.keys(data).forEach(item => {
            if (!data[item]) {
              data[item] = ''
            }
          })
          methods.reset({
            ...data
          })
          setPatientPlansList(data.convenios || [])
        } catch {
          toast.error('Não foi possível resgatar dados do paciente.')
        } finally {
          setLoading(false)
        }
      }
      handleGetPatient()
    }

    return () => methods.reset({})
  }, [patient])

  console.log('err', methods.formState.errors)

  return (
    <Dialog $fullWidth={false} maxWidth='md' open={open} onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent>
            <Tabs value={currentStep} $fullWidth={true} onChange={(_event, value) => setCurrentStep(value)}>
              <Tab label='Informações básicas' style={tabStyle} />
              <Tab label='Contatos' style={tabStyle} />
              <Tab label='Localização' style={tabStyle} />
              <Tab label='Convênios' style={tabStyle} />
            </Tabs>
            {loading ? (
              <Spinner minWidth='700px' minHeight='550px' />
            ) : (
              <SwipeableViews
                animateHeight={true}
                containerStyle={containerStyle}
                index={currentStep}
                onChangeIndex={index => setCurrentStep(index)}
              >
                <BasicInfo />
                <Contact />
                <Location />
                <HealthPlan patientPlansList={patientPlansList} setPatientPlansList={setPatientPlansList} />
              </SwipeableViews>
            )}
          </DialogContent>
          <DialogActions>
            <Row
              variant={Object.keys(methods?.formState?.errors)?.length > 0 ? 'between-center' : 'end-center'}
              width='100%'
              padding='10px 20px'
            >
              {Object.keys(methods?.formState?.errors)?.length > 0 && (
                <Text size='paragraph_2' fontWeight='bold' sx={{ textDecoration: 'underline' }} color='danger.main'>
                  Existem campos com alerta
                </Text>
              )}
              <Row gap='15px'>
                <Button variant='outlined' onClick={handleClose}>
                  Cancelar
                </Button>
                <Button type='submit' variant='contained' disabled={loading}>
                  Salvar informações
                </Button>
              </Row>
            </Row>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

const containerStyle = {
  width: '100%',
  maxWidth: '1100px',
  slideContainer: {
    height: 100,
    WebkitOverflowScrolling: 'touch'
  }
}

const tabStyle = {
  textTransform: 'initial',
  fontSize: '12px'
}

export default NewPatientModal
