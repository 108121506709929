import { useFormContext } from 'react-hook-form'

import { Row, Column, Input, Select, SelectAsync, Checkbox } from 'components'

import { getOrigemList } from 'services'

import { countriesList } from 'configs'

const Contact = () => {
  const { register, setValue, getValues } = useFormContext()

  return (
    <Column variant='start start' padding='15px 0'>
      <Row gap='15px' flexWrap='wrap'>
        <Input label='E-mail' {...register('email')} />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Checkbox
          label='Celular internacional?'
          defaultValue={getValues('celular_internacional')}
          {...register('celular_internacional')}
        />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Select label='País' options={countriesList || []} onChange={value => setValue('codigo_pais', value)} />
        <Input mask='(99) 99999-9999' label='Celular' {...register('celular')} />
        <Input mask='(99) 9999-9999' label='Telefone fixo' {...register('telefone')} />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Input label='Responsável' {...register('responsavel')} />
        <Input mask='(99) 99999-9999' label='Telefone responsável' {...register('telefone_responsavel')} />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <SelectAsync
          label='Origem'
          fetchAction={getOrigemList}
          keys={{ label: 'nome', value: 'id' }}
          defaultValue={getValues('indicacao')}
          onChange={value => setValue('indicacao', value)}
        />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Input label='Facebook' {...register('profile_facebook')} />
        <Input label='Instagram' {...register('profile_instagram')} />
        <Input label='Twitter' {...register('profile_twitter')} />
      </Row>
    </Column>
  )
}
export default Contact
