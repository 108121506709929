import { forwardRef } from 'react'
import styled from 'styled-components'
import { Calendar as ReactCalendar } from 'react-calendar'

import { Column } from 'components'

const Calendar = forwardRef(({ date = new Date(), setDate, color = 'primary', ...rest }, ref) => (
  <StyledColumn variant='start-center' color={color} padding='15px'>
    <ReactCalendar ref={ref} locale='pt-BR' onChange={setDate} defaultValue={date} {...rest} />
  </StyledColumn>
))

const StyledColumn = styled(Column)`
  .react-calendar {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    color: ${({ theme }) => theme.palette.neutral[500]};
  }
  .react-calendar__navigation {
    display: flex;
    margin: 15px 0;
  }
  .react-calendar__navigation button {
    color: ${({ theme, color }) => theme.palette[color].main};
    border: none;
    min-width: 44px;
    background: transparent;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: ${({ theme }) => theme.palette.neutral[100]};
    opacity: 0.5;
  }
  abbr[title] {
    text-decoration: none;
    font-weight: bold;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
  .react-calendar__tile {
    background-color: #fff;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.neutral[500]};
    border: ${({ theme }) => `1px solid ${theme.palette.neutral[100]}`};
    padding: 15px 0;
  }
  .react-calendar__tile--now {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    font-weight: bold;
    color: #fff;
    border: none;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    font-weight: bold;
    color: #fff;
    border: ${({ theme, color }) => `1px solid ${theme.palette[color].dark}`};
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    font-weight: bold;
  }
  .react-calendar__tile--active {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    font-weight: bold;
    color: #fff;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    color: white;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: ${({ theme, color }) => `1px solid ${theme.palette[color].dark}`};
  }
  .react-calendar__tile--range {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    color: #fff;
  }
  .react-calendar__tile--rangeStart {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    color: white;
  }
  .react-calendar__tile--rangeEnd {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    color: white;
  }
`

export default Calendar
