import { motion } from 'framer-motion'

const AnimatedContainer = ({ children, ...rest }) => (
  <motion.div
    layout
    layoutDependency
    initial={{ x: [-100, 20, 0], opacity: [0, 1] }}
    animate={{ x: [-100, 20, 0], opacity: [0, 1] }}
    transition={{ type: 'spring', stiffness: 700, damping: 30, duration: 0.8 }}
    style={{ width: '100%', minHeight: '100%' }}
    {...rest}
  >
    {children}
  </motion.div>
)

export default AnimatedContainer
