import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { startOfMonth, endOfMonth, addMonths, format, subMonths } from 'date-fns'

import Calendar from './Calendar'
import Sidebar from './Sidebar'

import { Row } from 'components'

import { useUpdateUser, useAuth } from 'hooks'

import { getMedicsAndRooms, getEventsSchedule, getWaitingRoom, getMedicTotals } from 'services'

import { formatEventData } from './Calendar/formatterData'

const Schedule = () => {
  const [loading, setLoading] = useState(false)
  const [selectedSchedule, setSelectedSchedule] = useState({})
  const [medicsAndRoomsList, setMedicsAndRoomsList] = useState([])

  const {
    auth: { usuario }
  } = useAuth()
  const { setUpdateEventsSchedule, setWaitingRoom, setMedicTotals } = useUpdateUser()

  const handleGetMedicAndRooms = async ({ id }) => {
    try {
      setLoading(true)
      const { data } = await getMedicsAndRooms()
      const medicTotals = await getMedicTotals(id)
      const waitingRoom = await getWaitingRoom(id)

      setMedicTotals(medicTotals?.data)
      setWaitingRoom(waitingRoom?.data?.results)
      setMedicsAndRoomsList(data?.results)
      setSelectedSchedule(data?.results.find(item => item.id === id))
    } catch {
      toast.error('Não foi possível resgatar listagem de médicos e salas.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetEventsSchedule = async ({ id, type, multiSchedule }) => {
    try {
      const { data } = await getEventsSchedule({
        id,
        initialDate: format(startOfMonth(subMonths(new Date(), 1)), 'dd/MM/yyyy'),
        finalDate: format(startOfMonth(addMonths(new Date(), 1)), 'dd/MM/yyyy'),
        type,
        multiSchedule
      })
      //Falta as configs
      setUpdateEventsSchedule({ configs: {}, events: formatEventData(data) })
    } catch {
      toast.error('Não foi possível resgatar eventos da agenda.')
    }
  }

  useEffect(() => {
    usuario && handleGetMedicAndRooms({ id: usuario?.is_doctor ? usuario?.id : usuario?.medico_pai })
    usuario && handleGetEventsSchedule({ id: usuario?.is_doctor ? usuario?.id : usuario?.medico_pai, type: 1 })
  }, [usuario])

  return (
    <Row gap='25px'>
      <Sidebar
        loading={loading}
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={setSelectedSchedule}
        medicsRoomsList={medicsAndRoomsList}
        handleGetEventsSchedule={handleGetEventsSchedule}
        handleGetMedicAndRooms={handleGetMedicAndRooms}
      />
      <Calendar />
    </Row>
  )
}

export default Schedule
