import { AnimatedContainer, PageHeader, UnderConstruction } from 'components'

const Ranking = () => {
  return (
    <AnimatedContainer>
      <PageHeader title='Ranking' />
      <UnderConstruction />
    </AnimatedContainer>
  )
}

export default Ranking
