import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { OverlayLoader } from 'components'

import { useAuth } from 'hooks'

import { getUserToken } from 'providers'

import { me } from 'services'

import NavigationRoutes from 'routes'

const App = () => {
  const [loading, setLoading] = useState(false)

  const { setAuth } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const token = getUserToken()

    const getUser = async () => {
      try {
        setLoading(true)
        const { data } = await me()
        setAuth({ token, ...data })
        navigate('dashboard')
      } catch {
        toast.error('Não foi possível resgatar dados do usuário.')
      } finally {
        setLoading(false)
      }
    }
    token && getUser()
  }, [])

  return loading ? <OverlayLoader /> : <NavigationRoutes />
}

export default App
