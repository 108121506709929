import { AnimatedContainer, PageHeader, UnderConstruction } from 'components'

const Transfers = () => {
  return (
    <AnimatedContainer>
      <PageHeader title='Tranferências' />
      <UnderConstruction />
    </AnimatedContainer>
  )
}

export default Transfers
