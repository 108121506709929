import { AnimatedContainer, PageHeader, UnderConstruction } from 'components'

const Reports = () => {
  return (
    <AnimatedContainer>
      <PageHeader title='Relatórios' />
      <UnderConstruction />
    </AnimatedContainer>
  )
}

export default Reports
