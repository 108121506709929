const Exclamation = ({ width = 24, height = 24, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M13.5996 6.40625C13.8047 6.40625 13.9805 6.58203 13.9512 6.78711L13.6289 13.3496C13.5996 13.5547 13.4531 13.6719 13.2773 13.6719H11.2559C11.0801 13.6719 10.9336 13.5547 10.9043 13.3496L10.582 6.78711C10.5527 6.58203 10.7285 6.40625 10.9336 6.40625H13.5996ZM12.2812 14.8438C13.3066 14.8438 14.1562 15.6934 14.1562 16.7188C14.1562 17.7734 13.3066 18.5938 12.2812 18.5938C11.2266 18.5938 10.4062 17.7734 10.4062 16.7188C10.4062 15.6934 11.2266 14.8438 12.2812 14.8438ZM13.5996 5H10.9336C9.9082 5 9.11719 5.84961 9.17578 6.875L9.49805 13.4375C9.52734 13.8184 9.67383 14.1699 9.9082 14.4629C9.35156 15.0488 9 15.8398 9 16.7188C9 18.5352 10.4648 20 12.2812 20C14.0684 20 15.5625 18.5352 15.5625 16.7188C15.5625 15.8398 15.1816 15.0488 14.625 14.4629C14.8594 14.1699 15.0059 13.8184 15.0352 13.4375L15.3574 6.875C15.416 5.84961 14.625 5 13.5996 5Z'
      fill={fill}
    />
  </svg>
)

export default Exclamation
