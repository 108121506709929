import { forwardRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import MuiCheckbox from '@mui/material/Checkbox'

import { Row, Text } from 'components'

const Checkbox = forwardRef(({ containerProps, labelProps, label, defaultValue = false, ...rest }, ref) => {
  const [checked, setChecked] = useState(defaultValue)
  const checkBoxId = uuidv4()

  return (
    <Row variant='start-center' {...containerProps}>
      <MuiCheckbox
        id={checkBoxId}
        ref={ref}
        size='small'
        checked={checked}
        sx={checkboxStyles}
        {...rest}
        onChange={event => setChecked(event.target.checked)}
      />
      {label && (
        <Text
          htmlFor={checkBoxId}
          component='label'
          size='helper'
          fontWeight='bold'
          color='primary'
          sx={{ cursor: 'pointer' }}
          {...labelProps}
        >
          {label}
        </Text>
      )}
    </Row>
  )
})

const checkboxStyles = { width: '18px', height: '18px', svg: { width: '18px', height: '18px' }, margin: '-3px 2px 0 0' }

export default Checkbox
