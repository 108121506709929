const EditIcon = ({ width = 32, height = 32, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <g clipPath='url(#clip0_260_3859)'>
      <path
        d='M13.9792 3.99992L17.1111 7.13186C17.2431 7.26381 17.2431 7.47908 17.1111 7.61103L9.52778 15.1944L6.30556 15.552C5.875 15.6006 5.51042 15.236 5.55903 14.8055L5.91667 11.5833L13.5 3.99992C13.6319 3.86797 13.8472 3.86797 13.9792 3.99992ZM19.6042 3.20478L17.9097 1.51034C17.3819 0.982558 16.5243 0.982558 15.9931 1.51034L14.7639 2.7395C14.6319 2.87145 14.6319 3.08672 14.7639 3.21867L17.8958 6.35061C18.0278 6.48256 18.2431 6.48256 18.375 6.35061L19.6042 5.12145C20.1319 4.5902 20.1319 3.73256 19.6042 3.20478ZM13.3333 13.1319V16.6666H2.22222V5.55547H10.2014C10.3125 5.55547 10.4167 5.51033 10.4965 5.43395L11.8854 4.04506C12.1493 3.78117 11.9618 3.33325 11.5903 3.33325H1.66667C0.746528 3.33325 0 4.07978 0 4.99992V17.2221C0 18.1423 0.746528 18.8888 1.66667 18.8888H13.8889C14.809 18.8888 15.5556 18.1423 15.5556 17.2221V11.743C15.5556 11.3714 15.1076 11.1874 14.8438 11.4478L13.4549 12.8367C13.3785 12.9166 13.3333 13.0208 13.3333 13.1319Z'
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_260_3859'>
        <rect width={width} height={height} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default EditIcon
