import { Box } from '@mui/material'
import { flexVariants } from 'configs'

const Row = ({ children, display = 'flex', variant = 'start-start', ...rest }) => (
  <Box display={display} {...flexVariants[variant]} {...rest}>
    {children}
  </Box>
)

export default Row
