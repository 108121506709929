import { Row, Text } from 'components'

import { HeartSolid } from 'assets'

const year = new Date().getFullYear()

const PageFooter = () => (
  <Row width='100%' margin='15px 0 0' padding='20px' borderTop='1px solid #e4eaec'>
    <Text
      fontSize='12px'
      color='neutral.500'
      textAlign='center'
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
      gap='3px'
    >
      © {year} - Feito com {<HeartSolid fill='#743A8E' />} por GestãoDS - CNPJ: 23.640.571/0001-96
    </Text>
  </Row>
)

export default PageFooter
