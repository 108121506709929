import { addMonths } from 'date-fns'
const tokenName = String(process.env.REACT_APP_COOKIE_TOKEN)
const tokenExpiration = Number(process.env.REACT_APP_COOKIE_EXPIRATION)

const expireTime = new Date()

export const getUserToken = () =>
  document.cookie
    .split(';')
    .filter(elem => elem.includes(tokenName))
    .join()
    .split('=')[1]

export const setUserToken = token =>
  (document.cookie = `${tokenName}=${token};expires=${addMonths(expireTime, tokenExpiration || 1)}`)

export const removeUserToken = () => {
  document.cookie = `${tokenName}=;`
  window.location.href = '/'
}
