import styled from 'styled-components'

const NavigateItem = ({ label, path, active, subPath, Icon, setOpen, navigate, ...rest }) => {
  const handlerClick = () => {
    setOpen(null)
    navigate(path)
  }

  return (
    <Item
      active={active}
      onClick={() => (subPath?.length > 0 ? setOpen({ label, subPath }) : handlerClick())}
      {...rest}
    >
      {Icon(active)}
      <Label active={active}>{label}</Label>
    </Item>
  )
}

const Item = styled.div`
  width: 100%;
  height: 100%;
  max-height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: ${({ theme, active }) => (active ? theme.palette.primary[50] : 'transparent')};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary[50]};
    p {
      color: ${({ theme }) => theme.palette.primary[400]};
    }
    svg {
      path {
        fill: ${({ theme }) => `${theme.palette.primary[400]} !important`};
      }
    }
  }
`

const Label = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme, active }) => (active ? theme.palette.primary[500] : theme.palette.neutral[500])};
  margin-top: 5px;
`

export default NavigateItem
