import { Window } from '@mui/icons-material'
import axios from 'axios'
import { toast } from 'react-toastify'

import { getUserToken, removeUserToken } from 'providers'

const __API__ = process.env.REACT_APP_API

const defaultConfig = {
  baseURL: __API__,
  withCredentials: true
}

const instance = axios.create(defaultConfig)

instance.interceptors.request.use(config => {
  const token = getUserToken()

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `token ${token}` : ''
    }
  }
})

instance.interceptors.response.use(
  data => data,
  error => {
    console.log('err', error)
    if (error.response.status === 401) {
      return removeUserToken()
    }

    return Promise.reject(error)
  }
)

export default instance
