const Syringe = ({ width = 32, height = 32, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M6.8656 6.30929L8.02613 7.4719C8.09072 7.53649 8.09072 7.64275 8.02613 7.70734L7.79069 7.94278C7.7261 8.00737 7.61984 8.00737 7.55525 7.94278L6.39472 6.78017L5.45087 7.72401L6.61349 8.88663C6.67808 8.95122 6.67808 9.05748 6.61349 9.12207L6.37805 9.3575C6.31346 9.42209 6.2072 9.42209 6.14261 9.3575L4.97999 8.19281L4.42994 8.74286C4.06949 9.10331 3.89656 9.5992 3.95073 10.1055L4.09866 11.4306L2.71519 12.8141C2.6506 12.8787 2.6506 12.9849 2.71519 13.0495L2.95063 13.285C3.01522 13.3496 3.12148 13.3496 3.18607 13.285L4.56745 11.9036L5.89258 12.0515C6.39055 12.1057 6.8906 11.939 7.25522 11.5723L11.0452 7.78235L8.21781 4.95499L6.8656 6.30929ZM13.2871 4.36535L11.6369 2.71519C11.5723 2.6506 11.466 2.6506 11.4015 2.71519L11.166 2.95063C11.1014 3.01522 11.1014 3.12148 11.166 3.18607L11.7557 3.77571L10.8118 4.71955L9.63253 3.54027L9.27833 3.18607C9.21374 3.12148 9.10748 3.12148 9.04289 3.18607L8.33657 3.89239C8.27198 3.95698 8.27198 4.06324 8.33657 4.12783L8.69077 4.48203L11.5181 7.31356L11.8723 7.66776C11.9369 7.73235 12.0432 7.73235 12.1078 7.66776L12.8141 6.95935C12.8787 6.89476 12.8787 6.7885 12.8141 6.72391L11.2827 5.19252L12.2265 4.24867L12.8162 4.83831C12.8808 4.9029 12.987 4.9029 13.0516 4.83831L13.2871 4.60287C13.3516 4.5362 13.3516 4.43202 13.2871 4.36535Z'
      fill={fill}
    />
  </svg>
)

export default Syringe
