import styled from 'styled-components'
import Popover from '@mui/material/Popover'

import { Row } from 'components'

const VisibleColumnsPopover = ({
  id,
  open,
  state,
  onClose,
  table,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  },
  ...rest
}) => (
  <Popover
    id={open ? id : undefined}
    open={open}
    anchorEl={state}
    onClose={onClose}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    {...rest}
  >
    <Row variant='start-center' padding='15px'>
      <Label>
        <input
          {...{
            type: 'checkbox',
            checked: table.getIsAllColumnsVisible(),
            onChange: table.getToggleAllColumnsVisibilityHandler()
          }}
        />
        Esconder todas
      </Label>
    </Row>
    {table.getAllLeafColumns().map(column => (
      <Row key={column.id} variant='start-center' padding='15px' borderTop='1px solid #e6e6e6'>
        <Label>
          <input
            {...{
              type: 'checkbox',
              checked: column.getIsVisible(),
              onChange: column.getToggleVisibilityHandler()
            }}
          />
          {column.columnDef.header}
        </Label>
      </Row>
    ))}
  </Popover>
)

const Label = styled.label`
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
`

export default VisibleColumnsPopover
