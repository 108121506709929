import { useState, useEffect } from 'react'

import { Row, InputIcon } from 'components'

import SearchIcon from '@mui/icons-material/Search'

const DebouncedInput = ({ initialValue, onChange, debounce = 500, ...props }) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <InputIcon
      value={value}
      onChange={e => setValue(e.target.value)}
      maxWidth='100%'
      autoComplete='false'
      placeholder='Pesquise na tabela...'
      errorMessage='Informe seu email'
      margin='0'
      height='auto'
      icon={<SearchIcon fontSize='24' color={'primary'} />}
      {...props}
    />
  )
}

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <Row>
      <DebouncedInput
        value={globalFilter || ''}
        onChange={event => setGlobalFilter(event)}
        placeholder='Pesquisar na tabela...'
      />
    </Row>
  )
}

export default GlobalFilter
