const ArrowDown = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 22 22'>
    <g>
      <path
        fill='#510972'
        d='M9.64,12.03c.38,.36,.87,.54,1.36,.54s.98-.18,1.36-.54L21.39,3.38c.78-.75,.81-1.99,.06-2.78s-1.99-.81-2.78-.06l-7.68,7.34L3.32,.55C2.54-.2,1.29-.18,.54,.61c-.75,.78-.72,2.03,.06,2.78L9.64,12.03Zm9.04-2.05l-7.68,7.34L3.32,9.97c-.78-.75-2.03-.72-2.78,.06-.75,.78-.72,2.03,.06,2.78l9.04,8.64c.38,.36,.87,.54,1.36,.54s.98-.18,1.36-.54l9.04-8.64c.78-.75,.81-1.99,.06-2.78-.75-.78-1.99-.81-2.78-.06Z'
      />
    </g>
  </svg>
)

export default ArrowDown
