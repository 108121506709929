import MuiButton from '@mui/material/Button'

const Button = ({ children, maxWidth = '280px', minWidth = '32px', minHeight = '28px', width, sx, ...rest }) => (
  <MuiButton
    sx={{
      width,
      padding: '4px 10px',
      maxWidth,
      minWidth,
      minHeight,
      fontSize: '14px',
      fontWeight: 'normal',
      textTransform: 'initial',
      ...sx
    }}
    {...rest}
  >
    {children}
  </MuiButton>
)

export default Button
