import { client } from 'providers'

export const getMedicsAndRooms = () => client.get('/agenda/listagem-medico-sala-equipamento/')

export const getEventsSchedule = ({ id, initialDate, finalDate, type, multiSchedule }) =>
  client.get(
    `agenda/eventos/${id}/?start=${initialDate}&end=${finalDate}&tipo=${type}${
      multiSchedule ? '&multi_agenda=true' : ''
    }`
  )

export const getScheduleConfig = ({ id, type }) =>
  client.get(`/agenda/config-sala-equipamento-medico/?idm=${id}&tipo=${type}`)

export const getMedicTotals = id => client.get(`/agenda/totais-dia-medico/${id}/`)

export const getWaitingRoom = id => client.get(`/agenda/pacientes-do-dia/${id}/`)

export const getMultiSchedule = () => client.get('/agenda/multi-agendaV2/')

export const putMultiSchedule = data => client.put('/agenda/multi-agendaV2/', data)
