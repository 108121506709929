import Popover from '@mui/material/Popover'

import { Calendar } from 'components'

const CalendarPopover = ({
  id,
  open,
  state,
  onClose,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center'
  },
  date,
  setDate,
  ...rest
}) => (
  <Popover
    id={open ? id : undefined}
    open={open}
    anchorEl={state}
    onClose={onClose}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    {...rest}
  >
    <Calendar date={date} setDate={setDate} selectRange={true} color='secondary' />
  </Popover>
)

export default CalendarPopover
