import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import pt from 'date-fns/locale/pt'

import { AuthProvider } from './context/AuthProvider'
import { UserProvider } from './context/UserProvider'
import { Theme } from 'configs'

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//   integrations: [new BrowserTracing()],
//   release: `gestaods-app.3.0 ${process.env.npm_package_version}`,
//   environment: process.env.REACT_APP_ENVIRONMENT,
//   tracesSampleRate: 1.0
// })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

setDefaultOptions({ locale: pt })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <BrowserRouter>
      <Theme>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserProvider>
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </UserProvider>
          </AuthProvider>
        </QueryClientProvider>
      </Theme>
    </BrowserRouter>
  </StrictMode>
)

reportWebVitals()
