import { Row } from 'components'

const CardHeader = ({ children }) => (
  <Row
    width='100%'
    variant='start-start'
    borderRadius='8px 8px 0 0'
    padding='15px 20px'
    borderBottom='1px solid #e6e6e6'
  >
    {children}
  </Row>
)

export default CardHeader
