import styled from 'styled-components'
import { Tooltip, Badge } from '@mui/material'

import { Text } from 'components'

const NavBarIcon = ({ icon, content, title }) => {
  return (
    <Tooltip title={title || ''}>
      <Base>
        <Badge badgeContent={content} max={9} color='danger' sx={badgeStyles}>
          {icon ? icon : ''}
        </Badge>
      </Base>
    </Tooltip>
  )
}

const badgeStyles = {
  '.MuiBadge-badge': {
    color: '#fff',
    width: '14px !important',
    height: '14px !important',
    fontSize: '10px !important',
    minWidth: '14px !important'
  }
}

const Base = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export default NavBarIcon
