const SearchIcon = ({ width = 24, height = 24, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M18.8974 17.8305L15.5872 14.5203C15.5051 14.4656 15.4231 14.4108 15.341 14.4108H14.9853C15.8334 13.426 16.3806 12.1128 16.3806 10.6903C16.3806 7.57157 13.809 5 10.6903 5C7.54421 5 5 7.57157 5 10.6903C5 13.8363 7.54421 16.3806 10.6903 16.3806C12.1128 16.3806 13.3986 15.8608 14.4108 15.0127V15.3683C14.4108 15.4504 14.4382 15.5325 14.4929 15.6146L17.8031 18.9248C17.9399 19.0616 18.1588 19.0616 18.2682 18.9248L18.8974 18.2956C19.0342 18.1861 19.0342 17.9673 18.8974 17.8305ZM10.6903 15.0674C8.2555 15.0674 6.31314 13.1251 6.31314 10.6903C6.31314 8.28285 8.2555 6.31314 10.6903 6.31314C13.0977 6.31314 15.0674 8.28285 15.0674 10.6903C15.0674 13.1251 13.0977 15.0674 10.6903 15.0674Z'
      fill={fill}
    />
  </svg>
)

export default SearchIcon
