import { Spinner } from 'components'

import { removeUserToken } from 'providers'

const Dashboard = () => {
  return (
    <div>
      Dashboard <Spinner />
      <button onClick={() => removeUserToken()}>logout</button>
    </div>
  )
}

export default Dashboard
