import styled from 'styled-components'

const Chip = ({ label = 'chip', ...rest }) => <Base {...rest}>{label}</Base>

const Base = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 80px;
  border-radius: 5px;
  padding: 2px 3px;
  font-size: ${({ fontSize = '10px' }) => fontSize};
  margin: ${({ margin = '0' }) => margin};
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '100%' }) => height};
  background-color: ${({ bgColor = '#212121' }) => bgColor};
  color: ${({ color = '#fff' }) => color};
  cursor: pointer;
  transition: all 0.5s ease;
  ${({ hover, color = '#fff', bgColor = '#212121' }) =>
    hover &&
    `&:hover {
      background-color: ${color};
      border: 1px solid ${bgColor};
      color: ${bgColor};
  }`}
`

export default Chip
