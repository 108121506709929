const BellExclamation = ({ width = 24, height = 24, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M12.4805 12.957C12.3711 12.9023 12.2617 12.9023 12.1523 12.9023C11.6602 12.9023 11.2773 13.2852 11.2773 13.75C11.2773 14.2422 11.6602 14.625 12.1523 14.625C12.6172 14.625 13 14.2422 13 13.75C13 13.6406 13 13.5312 12.9453 13.4219C12.8633 13.2031 12.6992 13.0391 12.4805 12.957ZM12.7539 8.5H11.5234C11.2773 8.52734 11.0859 8.71875 11.0859 8.96484V8.99219L11.4414 11.6172C11.4688 11.8359 11.6602 12 11.8789 12H12.3984C12.6172 12 12.8086 11.8359 12.8359 11.6172L13.1914 8.99219C13.1914 8.99219 13.1914 8.96484 13.1914 8.9375C13.1914 8.71875 13 8.52734 12.7539 8.5ZM12.1523 19C13.1094 19 13.9023 18.2344 13.9023 17.25H10.4023C10.4023 18.2344 11.168 19 12.1523 19ZM18.0313 14.9258C17.5117 14.3516 16.5 13.5039 16.5 10.6875C16.5 8.58203 15.0234 6.88672 13 6.44922V5.875C13 5.38281 12.6172 5 12.125 5C11.6602 5 11.25 5.38281 11.25 5.875C11.25 5.875 11.25 5.875 11.2773 5.875V6.44922C9.25391 6.88672 7.77734 8.58203 7.77734 10.6875C7.77734 13.5039 6.76563 14.3516 6.24609 14.9258C6.10938 15.0625 6 15.2812 6 15.5H6.02734C6.02734 15.9648 6.38281 16.375 6.90234 16.375H17.375C17.9219 16.375 18.25 15.9648 18.2773 15.5C18.2773 15.2812 18.168 15.0625 18.0313 14.9258ZM7.85938 15.0625C8.43359 14.2969 9.0625 13.0391 9.08984 10.7148C9.08984 10.7148 9.08984 10.7148 9.08984 10.6875C9.08984 9.01953 10.457 7.625 12.1523 7.625C13.8203 7.625 15.2148 9.01953 15.2148 10.6875C15.2148 10.7148 15.1875 10.7148 15.1875 10.7148C15.2148 13.0391 15.8438 14.3242 16.418 15.0625H7.85938Z'
      fill={fill}
    />
  </svg>
)

export default BellExclamation
