const GridIcon = ({ width = 24, height = 24, active, fill, ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M9.74074 5.00001H5.79012C5.35375 5.00001 5 5.35376 5 5.79013V9.74075C5 10.1771 5.35375 10.5309 5.79012 10.5309H9.74074C10.1771 10.5309 10.5309 10.1771 10.5309 9.74075V5.79013C10.5309 5.35376 10.1771 5.00001 9.74074 5.00001Z'
      fill={active ? '#743A8E' : fill ? fill : '#565656'}
    />
    <path
      d='M9.74074 13.6914H5.79012C5.35375 13.6914 5 14.0451 5 14.4815V18.4321C5 18.8685 5.35375 19.2222 5.79012 19.2222H9.74074C10.1771 19.2222 10.5309 18.8685 10.5309 18.4321V14.4815C10.5309 14.0451 10.1771 13.6914 9.74074 13.6914Z'
      fill={active ? '#743A8E' : fill ? fill : '#565656'}
    />
    <path
      d='M18.4321 13.6914H14.4815C14.0452 13.6914 13.6914 14.0451 13.6914 14.4815V18.4321C13.6914 18.8685 14.0452 19.2222 14.4815 19.2222H18.4321C18.8685 19.2222 19.2223 18.8685 19.2223 18.4321V14.4815C19.2223 14.0451 18.8685 13.6914 18.4321 13.6914Z'
      fill={active ? '#743A8E' : fill ? fill : '#565656'}
    />
    <path
      d='M18.4321 5H14.4815C14.0452 5 13.6914 5.35375 13.6914 5.79012V9.74074C13.6914 10.1771 14.0452 10.5309 14.4815 10.5309H18.4321C18.8685 10.5309 19.2223 10.1771 19.2223 9.74074V5.79012C19.2223 5.35375 18.8685 5 18.4321 5Z'
      fill={active ? '#743A8E' : fill ? fill : '#565656'}
    />
    <path
      d='M9.74074 5.00001H5.79012C5.35375 5.00001 5 5.35376 5 5.79013V9.74075C5 10.1771 5.35375 10.5309 5.79012 10.5309H9.74074C10.1771 10.5309 10.5309 10.1771 10.5309 9.74075V5.79013C10.5309 5.35376 10.1771 5.00001 9.74074 5.00001Z'
      stroke={active ? '#743A8E' : fill ? fill : '#565656'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.74074 13.6914H5.79012C5.35375 13.6914 5 14.0451 5 14.4815V18.4321C5 18.8685 5.35375 19.2222 5.79012 19.2222H9.74074C10.1771 19.2222 10.5309 18.8685 10.5309 18.4321V14.4815C10.5309 14.0451 10.1771 13.6914 9.74074 13.6914Z'
      stroke={active ? '#743A8E' : fill ? fill : '#565656'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.4321 13.6914H14.4815C14.0452 13.6914 13.6914 14.0451 13.6914 14.4815V18.4321C13.6914 18.8685 14.0452 19.2222 14.4815 19.2222H18.4321C18.8685 19.2222 19.2223 18.8685 19.2223 18.4321V14.4815C19.2223 14.0451 18.8685 13.6914 18.4321 13.6914Z'
      stroke={active ? '#743A8E' : fill ? fill : '#565656'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.4321 5H14.4815C14.0452 5 13.6914 5.35375 13.6914 5.79012V9.74074C13.6914 10.1771 14.0452 10.5309 14.4815 10.5309H18.4321C18.8685 10.5309 19.2223 10.1771 19.2223 9.74074V5.79012C19.2223 5.35375 18.8685 5 18.4321 5Z'
      stroke={active ? '#743A8E' : fill ? fill : '#565656'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default GridIcon
