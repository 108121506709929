import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Input, Text } from 'components'

import { useClickout } from 'hooks'

import { ArrowIcon } from 'assets'

const Select = ({
  maxWidth,
  margin,
  options = [],
  label,
  defaultValue,
  error,
  required = false,
  keys = { value: 'value', label: 'label' },
  labelProps,
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue)
  const [opened, setOpened] = useState(false)
  const [auxOptions, setAuxOptions] = useState(options || [])

  const { elementRef } = useClickout(false, setOpened, false)

  const handleSearch = text => {
    setValue(text)
    const filterSelect = text
      ? options.filter(item => item[keys.label]?.toLowerCase()?.includes(text?.toLowerCase()))
      : options
    setAuxOptions(filterSelect)
  }

  const handleSelectItem = async item => {
    setValue(item[keys.label])
    setOpened(false)
    onChange && onChange(item[keys.value])
  }

  useEffect(() => {
    options.length > 0 && setAuxOptions(options)
  }, [options])

  useEffect(() => {
    defaultValue && setValue(defaultValue)
  }, [defaultValue])

  return (
    <Base maxWidth={maxWidth} margin={margin} label={label}>
      {label && (
        <Text size='caption' color={error ? 'danger.main' : 'neutral.400'} {...labelProps}>
          {required && (
            <Text component='span' color='danger.main'>
              *
            </Text>
          )}
          {label}
        </Text>
      )}
      <Icon opened={opened} label={label}>
        <ArrowIcon />
      </Icon>
      <Input
        ref={elementRef}
        error={error}
        maxWidth='380px'
        margin='0'
        height='auto'
        padding='8px 25px 8px 8px'
        value={value}
        placeholder='Selecione...'
        onClick={() => setOpened(true)}
        onChange={e => handleSearch(e.target.value)}
        autoComplete='off'
        {...rest}
      />
      <SelectBase opened={opened}>
        {auxOptions.length > 0 ? (
          auxOptions.map((elem, idx) => (
            <Item key={`${idx}-${elem[keys.value]}`} onClick={() => handleSelectItem(elem)}>
              {elem[keys.label]}
            </Item>
          ))
        ) : (
          <Item>Sem opções...</Item>
        )}
      </SelectBase>
    </Base>
  )
}

const Base = styled.div`
  position: relative;
  width: fit-content;
  margin: ${({ label, margin = '0' }) => (label ? '5px 0' : margin)};
  max-width: ${({ maxWidth }) => maxWidth};
`
const SelectBase = styled.ul`
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  overflow: auto;
  background-color: #fff;
  height: ${({ opened }) => (opened ? 'auto' : '0')};
  z-index: 10;
  max-height: 250px;
  -webkit-box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
  -moz-box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
  box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
`

const Item = styled.li`
  cursor: pointer;
  padding: 7px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 30px;
  overflow: hidden;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

const Icon = styled.div`
  position: absolute;
  top: ${({ label }) => (label ? '25px' : '7px')};
  right: 10px;
  transition: all 0.5s ease;
  transform: ${({ opened }) => `rotate(${opened ? '0deg' : '180deg'})`};
`
export default Select
