import { useState, useEffect, useCallback, useRef } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel
} from '@tanstack/react-table'
import { rankItem } from '@tanstack/match-sorter-utils'

import { Spinner, Row, Column, EmptyPage, Text } from 'components'

import Pagination from './Pagination'
import Header from './Header'

import { ArrowIcon } from 'assets'

import { hexToRgb, formatDate } from 'helpers'

const TableAsync = ({
  tableName = 'GestãoDS',
  columns = [],
  loaderProps,
  total,
  CustomHeader = <div></div>,
  handlerData,
  dataTemplate,
  emptyTitle,
  emptyMessage,
  filterDate = false,
  filters = [],
  ...rest
}) => {
  const [sorting, setSorting] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [columnVisibility, setColumnVisibility] = useState({})
  const [configs, setConfigs] = useState({ next: null, previous: null, total_pages: 1, count: 0 })
  const [columnFilter, setColumnFilter] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [date, setDate] = useState([new Date(), new Date(2022, 10, 22)])
  const [page, setPage] = useState(1)
  const [searchFilters, setSearchFilters] = useState({})

  const tableRef = useRef()

  const { next, previous, total_pages, count } = configs

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank })
    return itemRank.passed
  }

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      sorting,
      columnVisibility,
      globalFilter,
      columnFilter
    },
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilter,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  })

  const fetchData = useCallback(async props => {
    try {
      const {
        data: { next, previous, total_pages, count },
        data: { results }
      } = await handlerData({ ...props })
      const formattedData = dataTemplate ? dataTemplate(results) : results
      setConfigs({ next, previous, total_pages, count })
      setData(formattedData)
    } catch {
      setError(true)
      toast.error('Não foi possível resgatar dados.')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const dates = filterDate ? { data_inicial: formatDate(date[0]), data_final: formatDate(date[1]) } : ''
    const delayTime = setTimeout(
      () => fetchData({ page, next, previous, ...dates, filters: { ...searchFilters } }),
      500
    )
    return () => clearTimeout(delayTime)
  }, [page, date, searchFilters])

  if (error) return <EmptyPage width='100%' height='500px' title='Ops... Ocorreu um erro no resgate de dados.' />

  return (
    <Column width='100%' margin='10px 0 0'>
      {loading ? (
        <Row width='100%' variant='center' height='300px' {...loaderProps}>
          <Spinner />
        </Row>
      ) : (
        <Column width='100%' variant='start-start'>
          <Row width='100%' variant='between-center' margin='15px 0 20px'>
            <Row>
              {total && (
                <Text color='neutral.500' fontWeight='bold' size='paragraph'>
                  Total de registros: {configs?.count}
                </Text>
              )}
              {CustomHeader}
            </Row>
            <Header
              tableName={tableName}
              table={table}
              tableRef={tableRef}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              date={date}
              setDate={setDate}
              filterDate={filterDate}
              filters={filters}
              setSearchFilters={setSearchFilters}
              data={data}
              columns={columns}
            />
          </Row>
          <ContainerTable>
            {data.length > 0 ? (
              <StyledTable ref={tableRef} {...rest}>
                <Thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <Th maxWidth={header?.columnDef?.width || '100%'} key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <Row
                              variant='between-center'
                              {...{
                                onClick: header.column.getToggleSortingHandler()
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: <ArrowIcon style={{ margin: '0 0 0 5px' }} />,
                                desc: <ArrowIcon style={{ transform: 'rotate(180deg)', margin: '0 0 0 5px' }} />
                              }[header.column.getIsSorted()] ?? null}
                            </Row>
                          )}
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map(row => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <Td key={cell.id} maxWidth={cell?.column?.columnDef?.width || '100%'}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </StyledTable>
            ) : (
              <Column variant='center'>
                <EmptyPage width='100%' height='500px' title={emptyTitle} text={emptyMessage} />
              </Column>
            )}
          </ContainerTable>
          {data.length > 0 && total_pages > 1 && (
            <Pagination
              totalPages={total_pages}
              count={count}
              next={next}
              previous={previous}
              page={page}
              setPage={setPage}
              loading={loading}
            />
          )}
        </Column>
      )}
    </Column>
  )
}

const ContainerTable = styled.div`
  width: 100%;
  max-height: 650px;
  overflow: auto;
`

const StyledTable = styled.table`
  width: 100%;
  position: relative;
`

const Thead = styled.thead`
  width: 100%;
`
const Tbody = styled.tbody`
  width: 100%;
`

const Tr = styled.tr`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  &:nth-child(odd) td {
    background-color: ${({ theme }) => hexToRgb(theme.palette.primary.light, 0.3)};
  }
`
const Th = styled.th`
  font-size: 14px;
  position: sticky;
  top: -1px;
  text-align: left;
  padding: 10px 10px;
  cursor: pointer;
  background: #fff;
  z-index: 1;
  transition: all 0.5s ease;
  width: ${({ maxWidth = '100%' }) => `${maxWidth}px`};
  border-bottom: 1px solid #e6e6e6;
  color: ${({ theme }) => theme.palette.neutral[500]};
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`
const Td = styled.td`
  padding: 15px 10px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 12px;
  width: ${({ maxWidth = '100%' }) => `${maxWidth}px`};
  color: ${({ theme }) => theme.palette.neutral[500]};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light} !important;
  }
`

export default TableAsync
