import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'

const Avatar = ({
  size,
  margin,
  src,
  defaultValue = 'https://icons.veryicon.com/png/o/internet--web/55-common-web-icons/person-4.png',
  name = 'User',
  upload,
  uploadState,
  ...rest
}) => {
  const [file, setFile] = useState(defaultValue)
  const [source, setSource] = useState({ src, name })

  const elementId = uuidv4()

  const uploadFile = e => {
    if (!e.target.files.length) {
      return false
    }
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      const data = { image: reader.result }
      setFile(data.image)
      uploadState && uploadState(data.image)
    }
  }

  useEffect(() => {
    setSource({ src, name })
  }, [src, name])

  return upload ? (
    <Base size={size} margin={margin} image={file}>
      {upload && (
        <>
          <LabelInput htmlFor={elementId}>Alterar foto</LabelInput>
          <Input
            accept='image/x-png,image/gif,image/jpeg'
            type='file'
            onChange={e => uploadFile(e)}
            id={elementId}
            {...rest}
          />
        </>
      )}
    </Base>
  ) : src ? (
    <Img src={source.src} size={size} margin={margin} alt={source.name} />
  ) : (
    <EmptyImg size={size} margin={margin}>
      {source.name ? source.name[0] : 'User'}
    </EmptyImg>
  )
}

const Base = styled.div`
  position: relative;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  margin: ${({ margin = '0' }) => margin};
  width: ${({ size = '128px' }) => size};
  height: ${({ size = '128px' }) => size};
  min-width: ${({ size = '128px' }) => size};
  min-height: ${({ size = '128px' }) => size};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.dark}`};
  background-image: ${({ image }) => `url(${image})`};
  &:hover label {
    bottom: -5px;
  }
`

const Img = styled.img`
  border-radius: 100%;
  overflow: hidden;
  margin: ${({ margin = '0' }) => margin};
  width: ${({ size = '128px' }) => size};
  height: ${({ size = '128px' }) => size};
  min-width: ${({ size = '128px' }) => size};
  min-height: ${({ size = '128px' }) => size};
`
const EmptyImg = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: ${({ margin = '0' }) => margin};
  width: ${({ size = '128px' }) => size};
  height: ${({ size = '128px' }) => size};
  min-width: ${({ size = '128px' }) => size};
  min-height: ${({ size = '128px' }) => size};
  background-color: #f6f6f6;
  font-size: 28px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.dark}`};
`

const Input = styled.input`
  display: none;
`
const LabelInput = styled.label`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -70px;
  height: 60px;
  padding: 10px 0 0;
  background-color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary[500]};
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`

export default Avatar
