import styled from 'styled-components'

import StatusChip from './StatusChip'

const scheduleStatusDict = {
  'Não confirmado': { label: 'Agendado', value: 'nao_confirmado', bgColor: '#5d9cec', color: '#fff' },
  'Em atendimento': { label: 'Em atendimento', value: 'em_atendimento', bgColor: '#4bc24b', color: '#fff' },
  'Em espera': { label: 'Em espera', value: 'em_espera', bgColor: '#4bc24b', color: '#fff' },
  Confirmado: { label: 'Confirmado', value: 'confirmado', bgColor: '#f28f2d', color: '#fff' },
  Cancelado: { label: 'Cancelado', value: 'cancelado', bgColor: '#f05050', color: '#fff' },
  Finalizado: { label: 'Finalizado', value: 'finalizado', bgColor: '#212121', color: '#fff' }
}

const ScheduleStatus = ({ status, ...rest }) => {
  return (
    <Base {...rest}>
      <StatusChip
        label={status}
        bgColor={scheduleStatusDict[status].bgColor}
        color={scheduleStatusDict[status].color}
      />
      <StatusChip
        label={status}
        bgColor={scheduleStatusDict[status].bgColor}
        color={scheduleStatusDict[status].color}
      />
      <StatusChip
        label={status}
        bgColor={scheduleStatusDict[status].bgColor}
        color={scheduleStatusDict[status].color}
      />
      <StatusChip
        label={status}
        bgColor={scheduleStatusDict[status].bgColor}
        color={scheduleStatusDict[status].color}
      />
      <StatusChip
        label={status}
        bgColor={scheduleStatusDict[status].bgColor}
        color={scheduleStatusDict[status].color}
      />
      <StatusChip
        label={status}
        bgColor={scheduleStatusDict[status].bgColor}
        color={scheduleStatusDict[status].color}
      />
    </Base>
  )
}

const Base = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export default ScheduleStatus
