const disableEvents = ['icon-login', 'icon-like']

export const timeFormat = {
  hour: '2-digit',
  minute: '2-digit',
  meridiem: 'short'
}

export const formatEventData = data => {
  const formatedData = data.map(item => ({
    ...item,
    display: item.rendering ? 'background' : 'auto',
    resourceId: item.id_usuario,
    backgroundColor: item.style.backgroundColor,
    borderColor: item.style.borderColor,
    textColor: item.style.color,
    editable: item?.rendering ? false : disableEvents.includes(item.status_icon) ? false : true,
    overlap: !item.rendering
  }))
  return formatedData
}
