import { AnimatedContainer, PageHeader, UnderConstruction } from 'components'

const TransferResources = () => {
  return (
    <AnimatedContainer>
      <PageHeader title='Repasses' />
      <UnderConstruction />
    </AnimatedContainer>
  )
}

export default TransferResources
