import { AnimatedContainer, PageHeader, UnderConstruction } from 'components'

const FinancialConfig = () => {
  return (
    <AnimatedContainer>
      <PageHeader title='Configurações financeiro' />
      <UnderConstruction />
    </AnimatedContainer>
  )
}

export default FinancialConfig
