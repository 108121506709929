import { Text, Row } from 'components'

const PageHeader = ({ title = 'Page header', ...rest }) => {
  return (
    <Row variant='between-center' {...rest}>
      <Text size='h5' color='neutral.400' fontWeight={600}>
        {title}
      </Text>
    </Row>
  )
}

export default PageHeader
