import { client } from 'providers'

export const getAllPatients = data => client.post('/pacientes/buscav2/', data)

export const getPatient = data => client.get(`/pacientes/${data}/`)

export const getAllTags = (search = '') =>
  client.get(`/pacientes/etiquetas/etiqueta/${search ? `?search=${search}` : ''}`)

export const postPatient = data => client.post('/pacientes/', data)

export const putPatient = (id, data) => client.put(`/pacientes/${id}`, data)

export const createPatientTag = data => client.post('/pacientes/etiquetas/etiqueta/', data)

export const verifyCpfPatient = data => client.get(`/pacientes/verifica-cpf/?cpf=${data}`)

export const updatePatientImage = (id, image) =>
  console.log('im', image, 'id', id) || client.post(`/pacientes/foto-paciente/${id}/`, { image })

export const getOcupationList = (search = '') => client.get(`/ocupacao/${search ? `?search=${search}` : ''}`)

export const getOrigemList = (search = '') => client.get(`/indicacao/${search ? `?search=${search}` : ''}`)

export const getHealthplanList = () => client.get('/operadora/listagem-select-v2/')
