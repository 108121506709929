const Rocket = ({ width = 32, height = 32, fill, active = false, ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M15.4688 7.25C14.7812 7.25 14.2188 7.8125 14.2188 8.5C14.2188 9.21875 14.7812 9.75 15.4688 9.75C16.1875 9.75 16.7188 9.21875 16.7188 8.5C16.7188 7.8125 16.1875 7.28125 15.4688 7.25ZM19.7812 4.625C19.7188 4.4375 19.5625 4.28125 19.375 4.21875C18.375 4 17.5625 4 16.7812 4C13.5312 4 11.5625 5.75 10.125 8H6.9375C6.4375 8.03125 5.84375 8.375 5.59375 8.84375L4.0625 11.9375C4.03125 12.0312 4 12.1562 4 12.25C4 12.6875 4.3125 13 4.75 13H7.625L7.3125 13.6875C7 14.2812 7.4375 14.7812 7.5 14.8438L9.15625 16.5C9.65625 17 10.2188 16.75 10.3125 16.6875L10.9688 16.375V19.25C10.9688 19.6875 11.3125 20 11.7188 20C11.8438 20 11.9688 19.9688 12.0625 19.9375L15.1562 18.4062C15.5938 18.1562 15.9688 17.5625 15.9688 17.0625V13.875C18.25 12.4062 19.9688 10.4688 19.9688 7.21875C20 6.4375 20 5.625 19.7812 4.625ZM15.1875 12.5938C14.0312 13.1875 11.4062 14.5 9.96875 15.1875L8.8125 14.0312C9.5 12.5938 10.8125 9.96875 11.375 8.8125C12.875 6.5 14.4688 5.5 16.7812 5.5C17.3438 5.5 17.8438 5.5 18.4062 5.59375C18.5 6.1875 18.5 6.6875 18.4688 7.21875C18.4688 9.5 17.5 11.125 15.1875 12.5938Z'
      fill={active ? '#743A8E' : fill ? fill : '#2C2C2C'}
    />
  </svg>
)

export default Rocket
