import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { InputIcon, Button, Spinner, Column, Checkbox, Text, AnimatedContainer } from 'components'

import { useAuth } from 'hooks'

import { setUserToken, setStorageItem, getStorageItem, removeStorageItem } from 'providers'

import { login, me } from 'services'

import { loginSchema } from 'schemas'

import { gestaoDsLogo } from 'assets'

const FormLogin = ({ setOption }) => {
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [loading, setLoading] = useState(false)
  const [saveEmail, setSaveEmail] = useState(true)
  const [statusRequest, setStatusRequest] = useState(false)

  const { setAuth } = useAuth()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ resolver: yupResolver(loginSchema) })

  const onSubmit = async data => {
    try {
      setLoading(true)
      saveEmail ? setStorageItem('user_email', data.username) : removeStorageItem('user_email')

      const response = await login(data)
      const responseMe = await me()

      setUserToken(response.data.token)
      setAuth({ token, ...responseMe.data })
      navigate('dashboard')
    } catch (err) {
      const { status } = err?.response

      if (status === 400) {
        setStatusRequest({ status: 'error', message: 'Credenciais inválidas' })
        return toast.error('Credenciais inválidas.')
      }

      toast.error('Não foi possível fazer seu login.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const userEmail = getStorageItem('user_email')
    if (userEmail) {
      setValue('username', userEmail)
    }
  }, [])

  return (
    <AnimatedContainer>
      <Column variant='center' width='100%'>
        <Column width='100%' maxWidth='320px'>
          <Image src={gestaoDsLogo} alt='GestãoDS' />
          <Text size='h5' color='neutral.500' fontWeight='bold'>
            Entre com sua conta
          </Text>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputIcon
              maxWidth='100%'
              type='email'
              label='Email'
              autoComplete='false'
              placeholder='Informe seu email'
              error={!!errors?.username}
              errorMessage='Informe seu email'
              icon={<AlternateEmailIcon fontSize='24' color={!!errors.username ? 'danger' : 'primary'} />}
              register={register('username')}
            />
            <Column width='100%' variant='center-start'>
              <Checkbox checked={saveEmail} onChange={() => setSaveEmail(!saveEmail)} label='Lembrar email' />
            </Column>
            <InputIcon
              maxWidth='100%'
              type={hiddenPassword ? 'password' : 'text'}
              label='Senha'
              autoComplete='false'
              placeholder='Informe sua senha'
              error={!!errors?.password}
              errorMessage='informe sua senha'
              icon={
                hiddenPassword ? (
                  <VisibilityIcon fontSize='24' color={!!errors.password ? 'danger' : 'primary'} />
                ) : (
                  <VisibilityOffIcon fontSize='24' color={!!errors.password ? 'danger' : 'primary'} />
                )
              }
              iconProps={{
                onClick: () => setHiddenPassword(!hiddenPassword)
              }}
              register={register('password')}
            />
            <Column width='100%' variant='end-end'>
              <Text size='caption' onClick={() => setOption(false)} color='primary' cursorPointer>
                Esqueceu a senha
              </Text>
            </Column>
            {statusRequest && (
              <Column mt='20px'>
                <Text size='caption' fontWeight='bold' color='danger.main'>
                  {statusRequest.message}
                </Text>
              </Column>
            )}
            <Button
              width='100%'
              maxWidth='100%'
              type='submit'
              variant='contained'
              sx={{ margin: '20px 0 0' }}
              disabled={loading}
              {...{ startIcon: loading ? <Spinner variant='loader' /> : '' }}
            >
              {loading ? 'Carregando' : 'Entrar'}
            </Button>
          </Form>
        </Column>
      </Column>
    </AnimatedContainer>
  )
}

const Image = styled.img`
  width: 180px;
  margin-bottom: 25px;
`
const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default FormLogin
