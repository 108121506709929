import { createTheme, ThemeProvider } from '@mui/material/styles'
import { createGlobalStyle } from 'styled-components'

import { palette } from './variants'

export const theme = createTheme({
  palette: palette,
  typography: {
    fontSize: 18
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1200
    }
  }
})

const GlobalStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    font-family: 'Source Sans Pro', sans-serif !important;
    list-style: none;
    scroll-behavior: smooth;
    border-collapse: collapse;
  }
  * a {
    width: fit-content;
    text-decoration: none;
  }
  #root{
    margin: 0 auto;
  }

.custom-gds-scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #743A8E; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #220430; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #765087; 
  }
}

`

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

export default Theme
