import { Typography } from '@mui/material'

import { fontSize } from 'configs'

const Text = ({ children, size = 'paragraph_2', color = '#212121', maxWidth, cursorPointer, ellipsis, ...rest }) => {
  const sx = {
    ...(ellipsis && { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }),
    ...(cursorPointer && { cursor: 'pointer' }),
    ...(maxWidth && { maxWidth: maxWidth })
  }

  return (
    <Typography textTransform='initial' fontSize={fontSize[size]} color={color} sx={sx} {...rest}>
      {children}
    </Typography>
  )
}

export default Text
