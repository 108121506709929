import styled from 'styled-components'

import Text from '../Text'

const TextArea = ({ label, labelProps, margin, maxWidth, height, error, required, register, ...rest }) => (
  <Base margin={margin} maxWidth={maxWidth} height={height}>
    {label && (
      <Text size='caption' color={error ? 'danger.main' : 'neutral.400'} {...labelProps}>
        {required && (
          <Text component='span' color='danger.main'>
            *
          </Text>
        )}
        {label}
      </Text>
    )}
    <StyledText error={error} {...register} {...rest}></StyledText>
  </Base>
)

const Base = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: ${({ height = 'auto' }) => height};
  margin: ${({ margin = '5px 0' }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
`

const StyledText = styled.textarea`
  width: 100%;
  border-radius: 3px;
  min-height: ${({ minHeight = '70px' }) => minHeight};
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.palette.danger.main}` : `1px solid ${theme.palette.neutral.dark}`};
  padding: ${({ padding = '7px 8px' }) => padding};
  resize: none;
`

export default TextArea
