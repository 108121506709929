import { CalendarAltIcon, UsdCircleIcon, RocketIcon, UsersIcon, GridIcon } from 'assets'

export const navegation = [
  {
    label: 'Pacientes',
    path: '/pacientes',
    Icon: active => <UsersIcon active={active} />,
    subPath: [
      {
        label: 'Todos os pacientes',
        path: '/pacientes/todos-pacientes'
      },
      {
        label: 'Aniversariantes',
        path: '/pacientes/aniversarios'
      },
      {
        label: 'Ranking',
        path: '/pacientes/ranking'
      },
      {
        label: 'Contatos',
        path: '/pacientes/contatos'
      }
    ]
  },
  {
    label: 'Agenda',
    path: '/agenda',
    Icon: active => <CalendarAltIcon active={active} />,
    subPath: []
  },
  {
    label: 'Serviços',
    path: '/servicos',
    Icon: active => <GridIcon active={active} />,
    subPath: [
      {
        label: 'Dashboard',
        path: '/servicos/dashboard'
      },
      {
        label: 'Atendimentos',
        path: '/servicos/relatorios'
      },
      {
        label: 'Orçamentos',
        path: '/servicos/orcamentos'
      },
      {
        label: 'Convênios',
        path: '/servicos/convenios'
      },
      {
        label: 'TISS',
        path: '/servicos/tiss'
      },
      {
        label: 'Vacinas',
        path: '/servicos/vacinas'
      }
    ]
  },
  {
    label: 'Marketing',
    path: '/marketing',
    Icon: active => <RocketIcon active={active} />,
    subPath: [
      {
        label: 'Origens',
        path: '/marketing/origens'
      },
      {
        label: 'Campanhas',
        path: '/marketing/campanhas'
      },
      {
        label: 'Oportunidades',
        path: '/marketing/oportunidades'
      },
      {
        label: 'NPS',
        path: '/marketing/nps'
      },
      {
        label: 'Whatsapp',
        path: '/marketing/whatsapp'
      },
      {
        label: 'E-mail',
        path: '/marketing/email'
      },
      {
        label: 'Configurações',
        path: '/marketing/marketing-configuracao'
      }
    ]
  },
  {
    label: 'Financeiro',
    path: '/financeiro',
    Icon: active => <UsdCircleIcon active={active} />,
    subPath: [
      {
        label: 'Transações',
        path: '/financeiro/transacoes'
      },
      {
        label: 'Relatórios',
        path: '/financeiro/relatorios'
      },
      {
        label: 'Transferências',
        path: '/financeiro/transferencias'
      },
      {
        label: 'Repasses',
        path: '/financeiro/repasses'
      },
      {
        label: 'Estoque',
        path: '/financeiro/estoque'
      },
      {
        label: 'Configurações',
        path: '/financeiro/financeiro-configuracao'
      }
    ]
  }
]
