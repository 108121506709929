import * as yup from 'yup'

export const patientSchema = yup.object().shape({
  nome_completo: yup.string().required(),
  nascimento: yup.string(),
  apelido: yup.string(),
  cpf: yup.string(),
  rg: yup.string(),
  tipo_sanguineo: yup.string(),
  estado_civil: yup.string(),
  nome_conjuge: yup.string(),
  nome_completo_mae: yup.string(),
  nome_completo_pai: yup.string(),
  data_obito: yup.string(),
  obs: yup.string(),
  sexo: yup.string(),
  indicacao: yup.string(),
  imagem: yup.string(),
  convenios_paciente: yup.array(),
  email: yup.string(),
  pais: yup.string(),
  celular: yup.string(),
  telefone_fixo: yup.string(),
  responsavel: yup.string(),
  telefone_responsavel: yup.string(),
  profile_facebook: yup.string(),
  profile_instagram: yup.string(),
  profile_twitter: yup.string(),
  cep: yup.string(),
  uf: yup.string(),
  cidade: yup.string(),
  bairro: yup.string(),
  endereco: yup.string(),
  numero: yup.string(),
  complemento: yup.string(),
  enviar_email_lembrete: yup.boolean(),
  enviar_email_marketing: yup.boolean(),
  enviar_parabens: yup.boolean(),
  enviar_parabens_whatsapp: yup.boolean(),
  enviar_sms: yup.boolean(),
  enviar_whatsapp_lembrete: yup.boolean(),
  enviar_whatsapp_marketing: yup.boolean()
})
