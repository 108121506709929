import { Link } from 'react-router-dom'
import { Button, Menu, MenuItem } from '@mui/material'

import { Text, Row } from 'components'
import Attendance from './Attendance'
import NavBarIcon from './NavBarIcon'

import { useConfig } from 'hooks'

import {
  gestaoDsLogoWhite,
  CogsIcon,
  BellExclamationIcon,
  TasksIcon,
  ExclamationIcon,
  WhatsappIcon,
  SearchIcon,
  QuestionIcon
} from 'assets'

const Header = ({ totals, anchorEl, setAnchorEl }) => {
  const { setOpenSidebar } = useConfig()
  return (
    <Row
      variant='between-center'
      sx={{ background: 'linear-gradient(90deg, #612D86 0%, #821FAB 42.72%, #7A6FBE 82.63%)' }}
      padding={Boolean(anchorEl) ? '16px 31.5px 16px 16px' : '16px'}
      flexDirection={{ mobile: 'column', tablet: 'column', desktop: 'row' }}
      gap='15px'
    >
      <Row gap='24px' flexDirection={{ mobile: 'column', tablet: 'column', desktop: 'row' }}>
        <Link to='dashboard' onClick={() => setOpenSidebar(false)}>
          <img src={gestaoDsLogoWhite} alt='GestãoDS' />
        </Link>
        <Attendance totals={totals} />
      </Row>
      <Row gap='10px' flexDirection={{ mobile: 'column', tablet: 'row', desktop: 'row' }}>
        <Row>
          <NavBarIcon icon={<SearchIcon fill='white' />} title='Pesquisar' />
          <NavBarIcon
            icon={<WhatsappIcon fill='white' />}
            title='Mensagens do dia
com envio pendente'
          />
          <NavBarIcon icon={<QuestionIcon fill='white' />} title='Central de ajuda' />
        </Row>
        <Text size='caption' color='white' fontStyle='italic'>
          Restam 5 dias grátis.
        </Text>
        <Button variant='contained' color='complementary'>
          <Text color='neutral.900'>Assine agora</Text>
        </Button>
        <Row gap='10px'>
          <NavBarIcon icon={<TasksIcon fill='white' />} title='Atividades' />
          <NavBarIcon
            content={totals?.lembretes?.total}
            icon={<BellExclamationIcon fill='white' />}
            title='Notificações'
          />
          <NavBarIcon icon={<ExclamationIcon fill='white' />} title='Atualizações' />
          <NavBarIcon icon={<CogsIcon fill='white' />} title='Configurações' />
        </Row>
        <Menu
          id='config-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          elevation={0}
        >
          <MenuItem onClick={() => {}}>Logout</MenuItem>
        </Menu>
      </Row>
    </Row>
  )
}

export default Header
