import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Row, Button } from 'components'

import VisibleColumnsPopover from './Popover/VisibleColumnsPopover'
import FilterPopover from './Popover/FilterPopover'
import OptionsPopover from './Popover/OptionsPopover'
import CalendarPopover from './Popover/CalendarPopover'
import GlobalFilter from './GlobalFilter'

import { ArrowIcon, FiltroIcon, CalendarIcon, GridIcon } from 'assets'

const Header = ({
  tableName,
  table,
  tableRef,
  globalFilter,
  setGlobalFilter,
  date,
  setDate,
  filterDate,
  filters,
  setSearchFilters,
  data,
  columns
}) => {
  const [visibleColumnsState, setVisibleColumnsState] = useState(null)
  const [filterState, setFilterState] = useState(null)
  const [optionsState, setOptionsState] = useState(null)
  const [calendarState, setCalendarState] = useState(null)

  const visibleColumnsId = uuidv4()
  const filterId = uuidv4()
  const optionsId = uuidv4()
  const calendarId = uuidv4()

  const boolVisibleColumnsId = Boolean(visibleColumnsState)
  const boolFilterId = Boolean(filterState)
  const boolOptionsId = Boolean(optionsState)
  const boolCalendarsId = Boolean(calendarState)

  return (
    <Row gap='10px' variant='center'>
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      <Button
        variant='contained'
        aria-describedby={boolVisibleColumnsId ? visibleColumnsId : undefined}
        onClick={event => setVisibleColumnsState(event.currentTarget)}
      >
        <GridIcon width='24px' height='24px' fill='#fff' />
      </Button>
      <VisibleColumnsPopover
        id={visibleColumnsId}
        open={boolVisibleColumnsId}
        state={visibleColumnsState}
        onClose={() => setVisibleColumnsState(null)}
        table={table}
      />
      {filterDate && (
        <Button
          variant='contained'
          aria-describedby={boolCalendarsId ? boolCalendarsId : undefined}
          onClick={event => setCalendarState(event.currentTarget)}
        >
          <CalendarIcon width='26px' height='26px' fill='#fff' />
        </Button>
      )}
      <CalendarPopover
        id={calendarId}
        open={boolCalendarsId}
        state={calendarState}
        onClose={() => setCalendarState(null)}
        date={date}
        setDate={setDate}
      />
      {filters.length > 0 && (
        <Button
          variant='contained'
          aria-describedby={boolFilterId ? filterId : undefined}
          onClick={event => setFilterState(event.currentTarget)}
        >
          <FiltroIcon fill='#fff' />
        </Button>
      )}
      <FilterPopover
        id={filterId}
        open={boolFilterId}
        state={filterState}
        filters={filters}
        setSearchFilters={setSearchFilters}
        onClose={() => setFilterState(null)}
      />
      <Button
        variant='contained'
        aria-describedby={boolOptionsId ? optionsId : undefined}
        onClick={event => setOptionsState(event.currentTarget)}
      >
        Opções <ArrowIcon fill='#fff' style={{ marginLeft: '5px' }} />
      </Button>
      <OptionsPopover
        id={optionsId}
        open={boolOptionsId}
        state={optionsState}
        onClose={() => setOptionsState(null)}
        tableRef={tableRef}
        tableName={tableName}
        data={data}
        columns={columns}
      />
    </Row>
  )
}

export default Header
