export const countriesList = [
  {
    value: '+93',
    label: '+93 Afeganistão'
  },
  {
    value: '+355',
    label: '+355 Albânia'
  },
  {
    value: '+213',
    label: '+213 Algéria'
  },
  {
    value: '+1684',
    label: '+1684 Samoa Americana'
  },
  {
    value: '+376',
    label: '+376 Andorra'
  },
  {
    value: '+244',
    label: '+244 Angola'
  },
  {
    value: '+1264',
    label: '+1264 Anguilla'
  },
  {
    value: '+672',
    label: '+672 Antártida'
  },
  {
    value: '+1268',
    label: '+1268 Antigua e Barbuda'
  },
  {
    value: '+54',
    label: '+54 Argentina'
  },
  {
    value: '+374',
    label: '+374 Armênia'
  },
  {
    value: '+297',
    label: '+297 Aruba'
  },
  {
    value: '+61',
    label: '+61 Austrália'
  },
  {
    value: '+43',
    label: '+43 Áustria'
  },
  {
    value: '+994',
    label: '+994 Azerbaijão'
  },
  {
    value: '+1242',
    label: '+1242 Bahamas'
  },
  {
    value: '+973',
    label: '+973 Bahrein'
  },
  {
    value: '+880',
    label: '+880 Bangladesh'
  },
  {
    value: '+246',
    label: '+246 Barbados'
  },
  {
    value: '+375',
    label: '+375 Bielorrússia'
  },
  {
    value: '+32',
    label: '+32 Bélgica'
  },
  {
    value: '+501',
    label: '+501 Belize'
  },
  {
    value: '+229',
    label: '+229 Benin'
  },
  {
    value: '+1441',
    label: '+1441 Bermuda'
  },
  {
    value: '+0975',
    label: '+0975 Butão'
  },
  {
    value: '+591',
    label: '+591 Bolívia'
  },
  {
    value: '+387',
    label: '+387 Bósnia e Herzegovina'
  },
  {
    value: '+267',
    label: '+267 Botswana'
  },
  {
    value: '+47',
    label: '+47 Ilha Bouvet'
  },
  {
    value: '+55',
    label: '+55 Brasil'
  },
  {
    value: '+246',
    label: '+246 Território Britânico do Oceano Índico'
  },
  {
    value: '+673',
    label: '+673 Brunei'
  },
  {
    value: '+359',
    label: '+359 Bulgária'
  },
  {
    value: '+226',
    label: '+226 Burkina Faso'
  },
  {
    value: '+257',
    label: '+257 Burundi'
  },
  {
    value: '+855',
    label: '+855 Camboja'
  },
  {
    value: '+237',
    label: '+237 Camarões'
  },
  {
    value: '+01',
    label: '+01 Canadá'
  },
  {
    value: '+238',
    label: '+238 Cabo Verde'
  },
  {
    value: '+1345',
    label: '+1345 Ilhas Cayman'
  },
  {
    value: '+236',
    label: '+236 República Centro-Africana'
  },
  {
    value: '+235',
    label: '+235 Chade'
  },
  {
    value: '+56',
    label: '+56 Chile'
  },
  {
    value: '+86',
    label: '+86 China'
  },
  {
    value: '+61',
    label: '+61 Ilha Christmas'
  },
  {
    value: '+672',
    label: '+672 Ilhas Cocos (Keeling)'
  },
  {
    value: '+57',
    label: '+57 Colômbia'
  },
  {
    value: '+269',
    label: '+269 Comores'
  },
  {
    value: '+242',
    label: '+242 Congo'
  },
  {
    value: '+242',
    label: '+242 Congo (DR)'
  },
  {
    value: '+682',
    label: '+682 Ilhas Cook'
  },
  {
    value: '+506',
    label: '+506 Costa Rica'
  },
  {
    value: '+225',
    label: '+225 Costa do Marfim'
  },
  {
    value: '+385',
    label: '+385 Croácia'
  },
  {
    value: '+53',
    label: '+53 Cuba'
  },
  {
    value: '+357',
    label: '+357 Chipre'
  },
  {
    value: '+420',
    label: '+420 República Tcheca'
  },
  {
    value: '+45',
    label: '+45 Dinamarca'
  },
  {
    value: '+253',
    label: '+253 Djibuti'
  },
  {
    value: '+1767',
    label: '+1767 Dominica'
  },
  {
    value: '+1809',
    label: '+1809 República Dominicana'
  },
  {
    value: '+593',
    label: '+593 Equador'
  },
  {
    value: '+20',
    label: '+20 Egito'
  },
  {
    value: '+503',
    label: '+503 El Salvador'
  },
  {
    value: '+240',
    label: '+240 Guiné Equatorial'
  },
  {
    value: '+291',
    label: '+291 Eritreia'
  },
  {
    value: '+372',
    label: '+372 Estônia'
  },
  {
    value: '+251',
    label: '+251 Etiópia'
  },
  {
    value: '+500',
    label: '+500 Ilhas Malvinas'
  },
  {
    value: '+298',
    label: '+298 Ilhas Faroe'
  },
  {
    value: '+679',
    label: '+679 Fiji'
  },
  {
    value: '+358',
    label: '+358 Finlândia'
  },
  {
    value: '+33',
    label: '+33 França'
  },
  {
    value: '+594',
    label: '+594 Guiana Francesa'
  },
  {
    value: '+689',
    label: '+689 Polinésia Francesa'
  },
  {
    value: '+33',
    label: '+33 Terras Austrais e Antárticas Francesas'
  },
  {
    value: '+241',
    label: '+241 Gabão'
  },
  {
    value: '+220',
    label: '+220 Gâmbia'
  },
  {
    value: '+995',
    label: '+995 Geórgia'
  },
  {
    value: '+49',
    label: '+49 Alemanha'
  },
  {
    value: '+233',
    label: '+233 Gana'
  },
  {
    value: '+350',
    label: '+350 Gibraltar'
  },
  {
    value: '+30',
    label: '+30 Grécia'
  },
  {
    value: '+299',
    label: '+299 Groelândia'
  },
  {
    value: '+1473',
    label: '+1473 Granada'
  },
  {
    value: '+590',
    label: '+590 Guadalupe'
  },
  {
    value: '+1671',
    label: '+1671 Guão'
  },
  {
    value: '+502',
    label: '+502 Guatemala'
  },
  {
    value: '+224',
    label: '+224 Guiné'
  },
  {
    value: '+245',
    label: '+245 Guiné-Bissau'
  },
  {
    value: '+592',
    label: '+592 Guiana'
  },
  {
    value: '+509',
    label: '+509 Haiti'
  },
  {
    value: '+672',
    label: '+672 Ilhas Heard e McDonald'
  },
  {
    value: '+39',
    label: '+39 Vaticano'
  },
  {
    value: '+504',
    label: '+504 Honduras'
  },
  {
    value: '+852',
    label: '+852 Hong Kong'
  },
  {
    value: '+36',
    label: '+36 Hungria'
  },
  {
    value: '+354',
    label: '+354 Islândia'
  },
  {
    value: '+91',
    label: '+91 Índia'
  },
  {
    value: '+62',
    label: '+62 Indonésia'
  },
  {
    value: '+98',
    label: '+98 Iran'
  },
  {
    value: '+964',
    label: '+964 Iraque'
  },
  {
    value: '+353',
    label: '+353 Irlanda'
  },
  {
    value: '+972',
    label: '+972 Israel'
  },
  {
    value: '+39',
    label: '+39 Itália'
  },
  {
    value: '+1876',
    label: '+1876 Jamaica'
  },
  {
    value: '+81',
    label: '+81 Japão'
  },
  {
    value: '+962',
    label: '+962 Jordânia'
  },
  {
    value: '+7',
    label: '+7 Cazaquistão'
  },
  {
    value: '+254',
    label: '+254 Quênia'
  },
  {
    value: '+686',
    label: '+686 Kiribati'
  },
  {
    value: '+850',
    label: '+850 Coreia do Norte'
  },
  {
    value: '+82',
    label: '+82 Coreia do Sul'
  },
  {
    value: '+965',
    label: '+965 Kuwait'
  },
  {
    value: '+996',
    label: '+996 Quirguistão'
  },
  {
    value: '+856',
    label: '+856 Laos'
  },
  {
    value: '+371',
    label: '+371 Letônia'
  },
  {
    value: '+961',
    label: '+961 Líbano'
  },
  {
    value: '+266',
    label: '+266 Lesoto'
  },
  {
    value: '+231',
    label: '+231 Libéria'
  },
  {
    value: '+218',
    label: '+218 Líbia'
  },
  {
    value: '+423',
    label: '+423 Liechtenstein'
  },
  {
    value: '+370',
    label: '+370 Lituânia'
  },
  {
    value: '+352',
    label: '+352 Luxemburgo'
  },
  {
    value: '+853',
    label: '+853 Macao'
  },
  {
    value: '+389',
    label: '+389 Macedônia'
  },
  {
    value: '+261',
    label: '+261 Madagascar'
  },
  {
    value: '+265',
    label: '+265 Malawi'
  },
  {
    value: '+60',
    label: '+60 Malásia'
  },
  {
    value: '+960',
    label: '+960 Maldivas'
  },
  {
    value: '+223',
    label: '+223 Mali'
  },
  {
    value: '+356',
    label: '+356 Malta'
  },
  {
    value: '+692',
    label: '+692 Ilhas Marshall'
  },
  {
    value: '+596',
    label: '+596 Martinica'
  },
  {
    value: '+222',
    label: '+222 Mauritânia'
  },
  {
    value: '+230',
    label: '+230 Maurício'
  },
  {
    value: '+269',
    label: '+269 Mayotte'
  },
  {
    value: '+52',
    label: '+52 México'
  },
  {
    value: '+691',
    label: '+691 Micronésia'
  },
  {
    value: '+373',
    label: '+373 Moldova'
  },
  {
    value: '+377',
    label: '+377 Mônaco'
  },
  {
    value: '+976',
    label: '+976 Mongólia'
  },
  {
    value: '+1664',
    label: '+1664 Montserrat'
  },
  {
    value: '+212',
    label: '+212 Marrocos'
  },
  {
    value: '+258',
    label: '+258 Moçambique'
  },
  {
    value: '+95',
    label: '+95 Birmânia'
  },
  {
    value: '+264',
    label: '+264 Namíbia'
  },
  {
    value: '+674',
    label: '+674 Nauru'
  },
  {
    value: '+977',
    label: '+977 Nepal'
  },
  {
    value: '+31',
    label: '+31 Holanda'
  },
  {
    value: '+599',
    label: '+599 Antilhas Holandesas'
  },
  {
    value: '+687',
    label: '+687 Nova Caledônia'
  },
  {
    value: '+64',
    label: '+64 Nova Zelândia'
  },
  {
    value: '+505',
    label: '+505 Nicarágua'
  },
  {
    value: '+227',
    label: '+227 Niger'
  },
  {
    value: '+234',
    label: '+234 Nigéria'
  },
  {
    value: '+683',
    label: '+683 Niue'
  },
  {
    value: '+672',
    label: '+672 Ilha Norfolk'
  },
  {
    value: '+1670',
    label: '+1670 Ilhas Marianas do Norte'
  },
  {
    value: '+47',
    label: '+47 Noruega'
  },
  {
    value: '+968',
    label: '+968 Omã'
  },
  {
    value: '+92',
    label: '+92 Paquistão'
  },
  {
    value: '+680',
    label: '+680 Palau'
  },
  {
    value: '+970',
    label: '+970 Palestina'
  },
  {
    value: '+507',
    label: '+507 Panamá'
  },
  {
    value: '+675',
    label: '+675 Papua-Nova Guiné'
  },
  {
    value: '+595',
    label: '+595 Paraguai'
  },
  {
    value: '+51',
    label: '+51 Peru'
  },
  {
    value: '+63',
    label: '+63 Filipinas'
  },
  {
    value: '+672',
    label: '+672 Ilhas Picárnia'
  },
  {
    value: '+48',
    label: '+48 Polônia'
  },
  {
    value: '+351',
    label: '+351 Portugal'
  },
  {
    value: '+1787',
    label: '+1787 Porto Rico'
  },
  {
    value: '+974',
    label: '+974 Catar'
  },
  {
    value: '+262',
    label: '+262 Reunião'
  },
  {
    value: '+40',
    label: '+40 Romênia'
  },
  {
    value: '+70',
    label: '+70 Rússia'
  },
  {
    value: '+250',
    label: '+250 Ruanda'
  },
  {
    value: '+290',
    label: '+290 Santa Helena'
  },
  {
    value: '+1869',
    label: '+1869 São Cristóvão'
  },
  {
    value: '+1758',
    label: '+1758 Santa Lúcia'
  },
  {
    value: '+508',
    label: '+508 São Pedro e Miquelon'
  },
  {
    value: '+1784',
    label: '+1784 São Vicente e Granadinas'
  },
  {
    value: '+684',
    label: '+684 Samoa'
  },
  {
    value: '+378',
    label: '+378 São Marino'
  },
  {
    value: '+239',
    label: '+239 Sao Tomé e Príncipe'
  },
  {
    value: '+966',
    label: '+966 Arábia Saudita'
  },
  {
    value: '+221',
    label: '+221 Senegal'
  },
  {
    value: '+381',
    label: '+381 Sérvia e Montenegro'
  },
  {
    value: '+248',
    label: '+248 Seicheles'
  },
  {
    value: '+232',
    label: '+232 República da Serra Leoa'
  },
  {
    value: '+65',
    label: '+65 Singapura'
  },
  {
    value: '+421',
    label: '+421 Eslováquia'
  },
  {
    value: '+386',
    label: '+386 Eslovênia'
  },
  {
    value: '+677',
    label: '+677 Ilhas Salomão'
  },
  {
    value: '+252',
    label: '+252 Somália'
  },
  {
    value: '+27',
    label: '+27 África do Sul'
  },
  {
    value: '+500',
    label: '+500 Ilhas Geórgia do Sul e Sandwich do Sul'
  },
  {
    value: '+34',
    label: '+34 Espanha'
  },
  {
    value: '+94',
    label: '+94 Sri Lanka'
  },
  {
    value: '+249',
    label: '+249 Sudão'
  },
  {
    value: '+597',
    label: '+597 Suriname'
  },
  {
    value: '+47',
    label: '+47 Esvalbarde'
  },
  {
    value: '+268',
    label: '+268 Suazilândia'
  },
  {
    value: '+46',
    label: '+46 Suécia'
  },
  {
    value: '+41',
    label: '+41 Suiça'
  },
  {
    value: '+963',
    label: '+963 Síria'
  },
  {
    value: '+886',
    label: '+886 Taiwan'
  },
  {
    value: '+992',
    label: '+992 Tajiquistão'
  },
  {
    value: '+255',
    label: '+255 Tanzânia'
  },
  {
    value: '+66',
    label: '+66 Tailândia'
  },
  {
    value: '+670',
    label: '+670 Timor-Leste'
  },
  {
    value: '+228',
    label: '+228 Togo'
  },
  {
    value: '+690',
    label: '+690 Toquelau'
  },
  {
    value: '+676',
    label: '+676 Tonga'
  },
  {
    value: '+1868',
    label: '+1868 Trinidad e Tobago'
  },
  {
    value: '+0216',
    label: '+0216 Tunísia'
  },
  {
    value: '+90',
    label: '+90 Turquia'
  },
  {
    value: '+7370',
    label: '+7370 Turcomenistão'
  },
  {
    value: '+1649',
    label: '+1649 Ilhas Turks e Caicos'
  },
  {
    value: '+688',
    label: '+688 Tuvalu'
  },
  {
    value: '+256',
    label: '+256 Uganda'
  },
  {
    value: '+380',
    label: '+380 Ucrânia'
  },
  {
    value: '+971',
    label: '+971 Emirados Árabes'
  },
  {
    value: '+44',
    label: '+44 Reino Unido'
  },
  {
    value: '+1',
    label: '+1 Estados Unidos'
  },
  {
    value: '+1',
    label: '+1 Ilhas Menores Distantes dos Estados Unidos'
  },
  {
    value: '+598',
    label: '+598 Uruguai'
  },
  {
    value: '+998',
    label: '+998 Uzbequistão'
  },
  {
    value: '+678',
    label: '+678 Vanuatu'
  },
  {
    value: '+58',
    label: '+58 Venezuela'
  },
  {
    value: '+84',
    label: '+84 Vietnam'
  },
  {
    value: '+1284',
    label: '+1284 Ilhas Virgens Inglesas'
  },
  {
    value: '+1340',
    label: '+1340 Ilhas Virgens (USA)'
  },
  {
    value: '+681',
    label: '+681 Wallis e Futuna'
  },
  {
    value: '+212',
    label: '+212 Saara Ocidental'
  },
  {
    value: '+967',
    label: '+967 Iêmen'
  },
  {
    value: '+260',
    label: '+260 Zâmbia'
  },
  {
    value: '+263',
    label: '+263 Zimbábue'
  }
]
