import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { Text, Input } from 'components'

import { useClickout, useDebounce } from 'hooks'

import { ArrowIcon } from 'assets'
import { toast } from 'react-toastify'

const SelectAsync = ({
  label,
  maxWidth,
  margin,
  error,
  labelProps,
  required = false,
  value = null,
  options = [],
  fetchParams = {},
  createParams = {},
  createKey = 'text',
  onChange,
  defaultValue = '',
  keys = { value: 'value', label: 'label' },
  fetchAction = () => console.error('error, fetchApi undefined'),
  createAction = () => console.error('error, createAction undefined'),
  ...rest
}) => {
  const [opened, setOpened] = useState(false)
  const [auxOptions, setAuxOptions] = useState(options || [])
  const [inputText, setInputText] = useState(value?.label || '')
  const [auxInputText, setAuxInputText] = useState('')
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [firstRender, setFirstRender] = useState(true)

  const { elementRef } = useClickout(false, setOpened, false)

  const debaucedValue = useDebounce(inputText, 500)

  const handleSearch = text => {
    setInputText(text)
    setAuxInputText(text)
  }

  const handleSelectItem = item => {
    setInputText(item[keys.label])
    setOpened(false)
    setAuxInputText('')
    onChange && onChange(item[keys.value])
  }

  const handleFetchApi = useCallback(
    async search => {
      try {
        setLoadingOptions(true)
        const response = await fetchAction(search)
        const formatData = response.data.results.filter(item =>
          item[keys.label].toLowerCase().includes(auxInputText.toLowerCase())
        )
        defaultValue &&
          firstRender &&
          setInputText(formatData.find(item => item[keys.value] === defaultValue)[keys.label])
        setAuxOptions(formatData)
      } catch (e) {
        toast.error('Não foi possível resgatar opções no momento.')
      } finally {
        setFirstRender(false)
        setLoadingOptions(false)
      }
    },
    [auxOptions]
  )

  const handleCreating = async () => {
    try {
      setLoadingOptions(true)
      await createAction({ ...createParams, [createKey]: auxInputText })
      await handleFetchApi()
      toast.success('Opção criado com sucesso.')
      setInputText(auxInputText)
      setAuxInputText('')
    } catch {
      toast.error('Não foi possível criar opção no momento.')
    } finally {
      setLoadingOptions(false)
      setOpened(false)
    }
  }

  useEffect(() => {
    handleFetchApi(auxInputText)
  }, [debaucedValue])

  return (
    <Base maxWidth={maxWidth} margin={margin} label={label}>
      {label && (
        <Text size='caption' color={error ? 'danger.main' : 'neutral.400'} {...labelProps}>
          {required && (
            <Text component='span' color='danger.main'>
              *
            </Text>
          )}
          {label}
        </Text>
      )}
      <Icon opened={opened} label={label}>
        <ArrowIcon />
      </Icon>
      <Input
        ref={elementRef}
        error={error}
        maxWidth='380px'
        margin='0'
        height='auto'
        padding='7px 25px 7px 8px'
        value={inputText}
        onClick={() => setOpened(true)}
        onChange={e => handleSearch(e.target.value)}
        placeholder='Digite para pesquisar...'
        {...rest}
      />
      <SelectBase ref={elementRef} opened={opened}>
        {auxOptions.length > 0 && !loadingOptions ? (
          auxOptions.map(item => (
            <Item key={item[keys.value]} onClick={() => handleSelectItem(item)}>
              {item[keys.label]}
            </Item>
          ))
        ) : (
          <Item onClick={() => handleCreating()}>
            {!loadingOptions ? `Deseja criar ${auxInputText}` : 'Carregando...'}
          </Item>
        )}
      </SelectBase>
    </Base>
  )
}

const Base = styled.div`
  position: relative;
  width: fit-content;
  margin: ${({ label }) => (label ? '5px 0 10px ' : '23px 0 10px')};
  max-width: ${({ maxWidth }) => maxWidth};
`
const SelectBase = styled.ul`
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  overflow: auto;
  background-color: #fff;
  z-index: 2;
  -webkit-box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
  -moz-box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
  box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
  max-height: 250px;
  height: ${({ opened }) => (opened ? 'auto' : '0')};
`
const Item = styled.li`
  cursor: pointer;
  padding: 7px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 30px;
  overflow: hidden;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

const Icon = styled.div`
  position: absolute;
  top: ${({ label }) => (label ? '25px' : '7px')};
  right: 10px;
  transition: all 0.5s ease;
  transform: ${({ opened }) => `rotate(${opened ? '0deg' : '180deg'})`};
`

export default SelectAsync
