const PlayIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 109.72 128'>
    <g>
      <path
        fill='#e0e0e0'
        d='M13.71,128c-7.82,0-13.71-6.4-13.71-13.71V13.71C0,6.46,5.83,0,13.72,0c2.48,0,4.96,.67,7.15,2.01L103.16,52.3c4.08,2.49,6.56,6.92,6.56,11.7s-2.49,9.21-6.56,11.7L20.87,125.99c-2.19,1.34-4.67,2.01-7.15,2.01Z'
      />
    </g>
  </svg>
)

export default PlayIcon
