import { useState, useRef } from 'react'
import styled from 'styled-components'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'

import { Column, EmptyPage, Row } from 'components'

import { hexToRgb } from 'helpers'

import { ArrowIcon } from 'assets'

const Table = ({ data = [], columns = [], emptyPage = true, emptyMessage = '', maxHeight = '650px', ...rest }) => {
  const [sorting, setSorting] = useState([])

  const tableRef = useRef()

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  })

  return (
    <ContainerTable maxHeight={maxHeight}>
      {data.length > 0 ? (
        <StyledTable ref={tableRef} {...rest}>
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Th maxWidth={header?.columnDef?.width || '100%'} key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <Row
                        variant='between-center'
                        {...{
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <ArrowIcon style={{ margin: '0 0 0 5px' }} />,
                          desc: <ArrowIcon style={{ transform: 'rotate(180deg)', margin: '0 0 0 5px' }} />
                        }[header.column.getIsSorted()] ?? null}
                      </Row>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id} maxWidth={cell?.column?.columnDef?.width || '100%'}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </StyledTable>
      ) : (
        emptyPage && (
          <Column variant='center'>
            <EmptyPage width='100%' height='300px' title='Nenhum registro encontrado' text={emptyMessage} />
          </Column>
        )
      )}
    </ContainerTable>
  )
}

const ContainerTable = styled.div`
  width: 100%;
  overflow: auto;
  max-height: ${({ maxHeight }) => maxHeight};
`

const StyledTable = styled.table`
  width: 100%;
  position: relative;
`

const Thead = styled.thead`
  width: 100%;
`
const Tbody = styled.tbody`
  width: 100%;
`

const Tr = styled.tr`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  &:nth-child(odd) td {
    background-color: ${({ theme }) => hexToRgb(theme.palette.primary.light, 0.3)};
  }
`
const Th = styled.th`
  font-size: 14px;
  position: sticky;
  top: -1px;
  text-align: left;
  padding: 10px 10px;
  cursor: pointer;
  background: #fff;
  z-index: 1;
  transition: all 0.5s ease;
  width: ${({ maxWidth = '100%' }) => `${maxWidth}px`};
  border-bottom: 1px solid #e6e6e6;
  color: ${({ theme }) => theme.palette.neutral[500]};
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`
const Td = styled.td`
  padding: 15px 10px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 12px;
  width: ${({ maxWidth = '100%' }) => `${maxWidth}px`};
  color: ${({ theme }) => theme.palette.neutral[500]};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light} !important;
  }
`

export default Table
