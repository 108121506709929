import { useState, useMemo } from 'react'

import {
  AnimatedContainer,
  PageHeader,
  Button,
  Column,
  Row,
  Card,
  CardBody,
  CardHeader,
  Switch,
  TableAsync
} from 'components'
import NewPatientModal from './NewPatientModal'

import { convertToDate, convertDate } from 'helpers'

import { getAllPatients } from 'services'

import { EditIcon } from 'assets'

const PatientsList = () => {
  const [openModal, setOpenModal] = useState(null)
  const [patient, setPatient] = useState(null)

  const columns = useMemo(
    () => [
      {
        header: 'Nome Completo',
        accessorKey: 'nome_completo',
        width: 700
      },
      {
        header: 'Cpf',
        accessorKey: 'cpf',
        width: 300
      },
      {
        header: 'Telefone',
        accessorKey: 'telefone',
        width: 300
      },
      {
        header: 'E-mail',
        accessorKey: 'email',
        width: 500
      },
      {
        header: 'Nascimento',
        accessorKey: 'nascimento_formatted',
        cell: ({ row: { original } }) => original.nascimento,
        width: 400
      },
      {
        header: 'Ações',
        accessorKey: 'actions',
        width: 100,
        cell: ({ row: { original } }) => (
          <Row>
            <Button
              minWidth='32px'
              width='22px'
              variant='contained'
              color='orange'
              onClick={() => {
                setPatient(original.id)
                setOpenModal(true)
              }}
            >
              <EditIcon width='22px' height='22px' fill='#fff' />
            </Button>
          </Row>
        )
      }
    ],
    []
  )

  const formatData = data =>
    data?.length > 0
      ? data?.map(item => ({
          ...item,
          nascimento: item.nascimento ? convertDate(item.nascimento, '-', '/') : '',
          nascimento_formatted: item.nascimento ? convertToDate(item.nascimento) : new Date()
        }))
      : []

  return (
    <AnimatedContainer>
      <PageHeader title='Todos os pacientes' />
      <Card>
        <CardHeader>
          <Row
            width='100%'
            gap='10px'
            justifyContent={justifyQuery}
            alignItems={alignQuery}
            flexDirection={mobileQuery}
          >
            <Column variant='center-start'>
              <Switch size='small' color='secondary' label='Exibir apenas meu pacientes' />
            </Column>
            <Row gap='10px' flexDirection={mobileQuery}>
              <Button width='210px' variant='outlined' color='secondary'>
                Mesclar pacientes
              </Button>
              <Button width='210px' variant='contained' color='secondary' onClick={() => setOpenModal(true)}>
                Novo paciente
              </Button>
            </Row>
          </Row>
        </CardHeader>
        <CardBody>
          <TableAsync
            handlerData={getAllPatients}
            columns={columns}
            dataTemplate={formatData}
            emptyMessage='Ops... Parece que não existe pacientes cadastrados ainda.'
            total
          />
        </CardBody>
      </Card>
      {openModal && <NewPatientModal open={openModal} patient={patient} handleClose={() => setOpenModal(null)} />}
    </AnimatedContainer>
  )
}

const mobileQuery = { mobile: 'column', tablet: 'column', desktop: 'row' }
const justifyQuery = { mobile: 'center', tablet: 'center', desktop: 'space-between' }
const alignQuery = { mobile: 'center', tablet: 'center', desktop: 'start' }

export default PatientsList
