import axios from 'axios'
import { removeMask } from 'helpers'
import { toast } from 'react-toastify'

export const getCep = async value => {
  try {
    const response = await axios.get(`https://viacep.com.br/ws/${removeMask(value)}/json/`)

    return response
  } catch {
    toast.error('Não foi possível resgatar cep')
  }
}
