import styled from 'styled-components'
import { Badge } from '@mui/material'

import { Text } from 'components'

const ButtonAttendance = ({ label = 'Botão', content, icon }) => {
  return (
    <Badge badgeContent={content} max={9} color='danger' sx={badgeStyles}>
      <Button size='small' variant='outlined' color='neutral'>
        {icon ? icon : ''}
        <Text mt='2px' size='caption' color='primary.400'>
          {label}
        </Text>
      </Button>
    </Badge>
  )
}

const badgeStyles = {
  '.MuiBadge-badge': {
    color: '#fff',
    width: '14px !important',
    height: '14px !important',
    fontSize: '10px !important',
    minWidth: '14px !important'
  }
}

const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  border-radius: 50px;
  border: none;
  padding: 0 10px;
  background-color: #eee6f1;
  cursor: pointer;
  transition: all 0.5s ease;
  max-width: ${({ maxWidth = '100%' }) => maxWidth};
  &:hover {
    opacity: 0.8;
  }
`

export default ButtonAttendance
