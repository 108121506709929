import { useState } from 'react'

import { Column, Avatar, Row, Text, Chip } from 'components'

import { useUpdateUser } from 'hooks'

import { statusColorsDict } from 'configs'

import { EnvelopeIcon, DollarIcon, ClockIcon } from 'assets'

const WaitingRoom = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const { waitingRoom } = useUpdateUser()

  return (
    <Column
      width='100%'
      maxHeight='450px'
      overflow='auto'
      padding='15px'
      mt='20px'
      borderRadius='5px'
      boxShadow='rgb(0 0 0 / 5%) 5px 5px 10px 5px;'
      className='custom-gds-scrollbar'
    >
      <Text>Sala de espera</Text>
      {waitingRoom.length > 0 ? (
        waitingRoom.map((scheduleInfo, index) => (
          <>
            <Row
              aria-describedby={`${scheduleInfo?.id}_${index}`}
              id={`${scheduleInfo?.id}_${index}`}
              width='100%'
              variant='between-center'
              borderBottom='1px solid #e6e6e6'
              padding='10px 5px'
              gap='3px'
              sx={customRowStyle}
              onClick={event => setAnchorEl(anchorEl ? null : event.currentTarget)}
            >
              <Avatar size='32px' src={scheduleInfo?.paciente_foto} name={scheduleInfo?.paciente_nome} />
              <Column variant='center'>
                <Text fontSize='10px' maxWidth='100px' ellipsis>
                  {scheduleInfo?.paciente_nome}
                </Text>
                <Row gap='5px'>
                  <EnvelopeIcon fill={scheduleInfo?.obs ? '#136CDC' : '#212121'} />
                  <DollarIcon fill={scheduleInfo?.id_pagamento ? '#1C8735' : '#212121'} />
                </Row>
                {scheduleInfo?.convenio_nome && (
                  <Text fontSize='10px' ellipsis maxWidth='100px'>
                    {scheduleInfo?.convenio_nome}
                  </Text>
                )}
              </Column>
              <Column>
                <Row m='3px 0' variant='end-center' width='100%' gap='5px'>
                  <ClockIcon />
                  <Text fontSize='12px' textAlign='right'>
                    {scheduleInfo?.hr_agendamento}
                  </Text>
                </Row>
                <Chip
                  label={scheduleInfo?.status}
                  color={statusColorsDict[scheduleInfo?.status]?.color}
                  bgColor={statusColorsDict[scheduleInfo?.status]?.bgColor}
                />
              </Column>
            </Row>
          </>
        ))
      ) : (
        <Text>Sem pacientes em espera</Text>
      )}
    </Column>
  )
}

const customRowStyle = {
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#e6e6e6'
  }
}

export default WaitingRoom
