import { Switch as MuiSwitch } from '@mui/material'

import { Row, Text } from 'components'

const Switch = ({ label, rowProps, labelProps, ...rest }) => (
  <Row variant='center' {...rowProps}>
    <MuiSwitch color='primary' sx={{ marginLeft: '10px' }} {...rest} />
    {label ? (
      <Text size='caption' color='neutral.500' {...labelProps}>
        {label}
      </Text>
    ) : (
      ''
    )}
  </Row>
)

export default Switch
