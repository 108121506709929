const QuestionIcon = ({ width = 24, height = 24, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M11.5189 3C8.88369 3 6.8895 4.10393 5.39386 6.31178C4.71726 7.30888 4.93092 8.66208 5.89241 9.4099L7.21 10.407C7.5661 10.6919 8.06465 10.8699 8.52759 10.8699C8.13587 11.5465 7.92221 12.2944 7.92221 13.149V13.5763C7.92221 14.1461 8.13587 14.6803 8.49198 15.072C7.8866 15.7486 7.5661 16.6032 7.5661 17.5291C7.5661 19.5945 9.20419 21.2326 11.2696 21.2326C13.2994 21.2326 14.9731 19.5945 14.9731 17.5291C14.9731 16.6032 14.617 15.7486 14.0116 15.072C14.3321 14.7515 14.5458 14.2886 14.5814 13.8256C15.5785 13.2202 18 12.0807 18 8.91135C18 5.56396 14.7594 3 11.5189 3ZM11.2696 19.5233C10.1657 19.5233 9.27541 18.633 9.27541 17.5291C9.27541 16.4608 10.1657 15.5349 11.2696 15.5349C12.3379 15.5349 13.2638 16.4608 13.2638 17.5291C13.2638 18.633 12.3379 19.5233 11.2696 19.5233ZM12.8721 13.2558V13.5763C12.8721 13.8612 12.6228 14.1105 12.3379 14.1105H10.1657C9.88079 14.1105 9.63152 13.8612 9.63152 13.5763V13.149C9.63152 11.4753 10.8779 10.7987 11.8394 10.2646C12.694 9.80162 13.1926 9.48112 13.1926 8.87574C13.1926 8.0567 12.1599 7.52254 11.3052 7.52254C10.2369 7.52254 9.70274 8.0567 9.02614 8.94696C8.81247 9.16063 8.45637 9.23185 8.2427 9.01819L6.92511 8.0567C6.67584 7.87865 6.64023 7.52254 6.78267 7.27327C7.92221 5.63518 9.31102 4.70931 11.5189 4.70931C13.8336 4.70931 16.2907 6.52545 16.2907 8.91135C16.2907 12.0451 12.8721 12.1163 12.8721 13.2558Z'
      fill={fill}
    />
  </svg>
)

export default QuestionIcon
