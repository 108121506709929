import { useNavigate, useLocation, NavLink } from 'react-router-dom'
import styled from 'styled-components'

import NavigateItem from './NavigateItem'
import { Text, Column, Row } from 'components'

import { useConfig, useClickout } from 'hooks'

import { navegation } from '../../configs/navegation'

import { HamburgerOpenIcon } from 'assets'

const Sidebar = () => {
  const { openSidebar, setOpenSidebar } = useConfig()
  const location = useLocation()
  const navigate = useNavigate()
  const pathName = location.pathname.split('/')

  const { elementRef } = useClickout(false, setOpenSidebar, false)

  return (
    <>
      <Column variant='start-start' sx={container}>
        {navegation?.map(({ label, path, subPath, Icon }, index) => (
          <NavigateItem
            label={label}
            path={path}
            subPath={subPath}
            Icon={Icon}
            key={index}
            setOpen={setOpenSidebar}
            navigate={navigate}
            active={path.split('/')[1] === pathName[1]}
          />
        ))}
      </Column>
      <Menu ref={elementRef} variant='start-start' open={openSidebar}>
        <Column variant='start-start' width='100%' padding='16px'>
          <Row width='100%' variant='between-center'>
            <Text size='h6' fontWeight='bold' color='neutral.400' sx={cursorPointer}>
              {openSidebar?.label}
            </Text>
            <HamburgerOpenIcon style={{ cursor: 'pointer' }} onClick={() => setOpenSidebar(null)} />
          </Row>
          <Column variant='start-start'>
            {openSidebar?.subPath?.length > 0
              ? openSidebar?.subPath?.map(({ label, path }, index) => (
                  <MenuItem active={path.split('/')[2] === pathName[2] ? 1 : 0} key={index} to={path}>
                    {label}
                  </MenuItem>
                ))
              : ''}
          </Column>
        </Column>
      </Menu>
    </>
  )
}

const container = {
  width: '100%',
  height: '100%',
  maxWidth: '78px',
  position: 'fixed',
  top: { mobile: 385, tablet: 178, desktop: 78 },
  left: 0,
  zIndex: 11,
  backgroundColor: 'white',
  overflow: 'hidden'
}

const cursorPointer = { cursor: 'pointer' }

const Menu = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  opacity: ${({ open }) => (open ? 1 : 0)};
  max-width: ${({ open }) => (open ? '278px' : '78px')};
  min-width: ${({ open }) => (open ? '278px' : '78px')};
  padding: ${({ open }) => (open ? '65px 0 0 78px' : '65px 0 0 0')};
  transition: all 0.5s ease;
  background-color: ${({ theme }) => theme.palette.neutral.main};
  z-index: 10;
  @media only screen and (max-width: 1199px) {
    padding: ${({ open }) => (open ? '165px 0 0 78px' : '165px 0 0 0')};
  }
  @media only screen and (max-width: 767px) {
    padding: ${({ open }) => (open ? '385px 0 0 78px' : '370px 0 0 0')};
  }
`

const MenuItem = styled(NavLink)`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  margin: 16px 0 0;
  transition: all 0.3s ease;
  white-space: nowrap;
  color: ${({ theme, active }) => (active ? theme.palette.primary[400] : theme.palette.neutral[400])};
  ${({ theme, active }) =>
    active
      ? `
    border-left: 3px solid ${theme.palette.primary[400]};
    padding-left: 8px;
  `
      : ''};

  &:hover {
    border-left: ${({ theme }) => `3px solid ${theme.palette.primary[400]}`};
    padding-left: 8px;
    color: ${({ theme }) => theme.palette.primary[400]};
  }
`
export default Sidebar
