const Users = ({ width = 32, height = 32, fill = '#656565', active = false, ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M16.5 12.3C17.9906 12.3 19.2 11.0906 19.2 9.6C19.2 8.10938 17.9906 6.9 16.5 6.9C15.0094 6.9 13.8 8.10938 13.8 9.6C13.8 11.0906 15.0094 12.3 16.5 12.3ZM10.65 12.8625C10.3125 12.8625 9.975 12.9188 9.6375 13.0313C9.24375 13.1438 8.82187 13.2 8.37187 13.2C7.95 13.2 7.52813 13.1438 7.13438 13.0313C6.79688 12.9188 6.45937 12.8625 6.12187 12.8625C5.10938 12.8625 4.125 13.3406 3.53438 14.1844C3.19688 14.7188 3 15.3375 3 16.0125V17.25C3 18.0094 3.59062 18.6 4.35 18.6H12.45C13.1812 18.6 13.8 18.0094 13.8 17.25V16.0125C13.8 15.3375 13.575 14.7188 13.2375 14.1844C12.6469 13.3406 11.6625 12.8625 10.65 12.8625ZM8.4 12.3C10.1156 12.3 11.55 10.8938 11.55 9.15C11.55 7.43438 10.1156 6 8.4 6C6.65625 6 5.25 7.43438 5.25 9.15C5.25 10.8938 6.65625 12.3 8.4 12.3Z'
      fill={active ? '#743A8E' : fill ? fill : '#2C2C2C'}
    />
    <path
      d='M18.3094 12.9188C19.1813 12.9188 19.9969 13.3125 20.475 14.0438C20.7844 14.4656 20.9531 14.9719 20.9531 15.5344V16.575C20.9531 17.2219 20.4469 17.7 19.8281 17.7H14.5969C14.625 17.5594 14.6531 17.4187 14.6531 17.25V16.35C14.6684 15.5643 14.4531 14.5 14.2875 14.2969C14.175 14.1 14.0625 13.875 13.95 13.6781C13.8094 13.5094 13.6406 13.3125 13.5 13.1719C13.8375 13.0031 14.175 12.9188 14.5687 12.9188C15.3562 12.9188 15.5531 13.2 16.4531 13.2C17.3531 13.2 17.55 12.9188 18.3094 12.9188Z'
      fill={active ? '#743A8E' : fill ? fill : '#2C2C2C'}
    />
  </svg>
)

export default Users
