import { AnimatedContainer, PageHeader, UnderConstruction } from 'components'

const BirthDays = () => {
  return (
    <AnimatedContainer>
      <PageHeader title='Aniversariantes' />
      <UnderConstruction />
    </AnimatedContainer>
  )
}

export default BirthDays
