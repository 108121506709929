import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Input, Text, Checkbox } from 'components'

import { useClickout } from 'hooks'

import { ArrowIcon } from 'assets'

const MultiSelect = ({
  maxWidth,
  margin,
  height,
  options,
  label,
  required = false,
  defaultValue = [],
  error,
  renderChips,
  chipsKeys = { color: 'color', bgColor: 'bgColor' },
  keys = { value: 'value', label: 'label' },
  labelProps,
  chipProps,
  onChange,
  ...rest
}) => {
  const [values, setValues] = useState(defaultValue)
  const [auxValues, setAuxValues] = useState([])
  const [opened, setOpened] = useState(false)
  const [auxOptions, setAuxOptions] = useState(options || [])

  const { elementRef } = useClickout(false, setOpened, false)

  const handleSearch = text => {
    setValue(text)
    const filterSelect = text
      ? options.filter(item => item[keys.label]?.toLowerCase()?.includes(text?.toLowerCase()))
      : options
    setAuxOptions(filterSelect)
  }

  const handleSelectItem = item => {
    if (values.find(l => l[keys.label] === item[keys.label])) {
      const filter = values.filter(l => l[keys.label] !== item[keys.label])
      const auxFilter = auxValues.filter(l => l !== item[keys.label])
      setAuxValues(auxFilter)
      onChange && onChange(filter)
      return setValues(filter)
    }
    setAuxValues([...auxValues, item[keys.label]])
    onChange && onChange([...values, item])
    return setValues([...values, item])
  }

  useEffect(() => {
    options.length > 0 && setAuxOptions(options)
  }, [options])

  useEffect(() => {
    defaultValue && setValues(defaultValue)
  }, [defaultValue])

  return (
    <Base maxWidth={maxWidth} margin={margin} label={label}>
      {label && (
        <Text size='caption' color={error ? 'danger.main' : 'neutral.400'} {...labelProps}>
          {required && (
            <Text component='span' color='danger.main'>
              *
            </Text>
          )}
          {label}
        </Text>
      )}
      <Icon opened={opened} label={label}>
        <ArrowIcon />
      </Icon>
      {renderChips ? (
        <ContainerChips ref={elementRef} onClick={() => setOpened(true)}>
          {values.length > 0 &&
            values.map(elem => (
              <Chip color={elem[chipsKeys.color]} bgColor={elem[chipsKeys.bgColor]} {...chipProps}>
                {elem[keys.label]}
              </Chip>
            ))}
        </ContainerChips>
      ) : (
        <Input
          ref={elementRef}
          maxWidth='380px'
          margin='0'
          height='auto'
          padding='7px 25px 7px 8px'
          value={auxValues.join(', ')}
          placeholder='Selecione...'
          onClick={() => setOpened(true)}
          onChange={e => handleSearch(e.target.value)}
          {...rest}
        />
      )}
      <SelectBase ref={elementRef} opened={opened}>
        {auxOptions.length > 0 ? (
          auxOptions.map((elem, idx) => (
            <Item key={`${idx}-${elem[keys.value]}`} onClick={() => handleSelectItem(elem)}>
              <Checkbox color='secondary' checked={!!values.find(l => l[keys.value] === elem[keys.value])} />
              {elem[keys.label]}
            </Item>
          ))
        ) : (
          <Item>Sem opções...</Item>
        )}
      </SelectBase>
    </Base>
  )
}

const Base = styled.div`
  position: relative;
  width: fit-content;
  min-width: 100%;
  margin: ${({ label }) => (label ? '5px 0' : '0')};
  max-width: ${({ maxWidth }) => maxWidth};
`
const SelectBase = styled.ul`
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  overflow: auto;
  background-color: #fff;
  height: ${({ opened }) => (opened ? '250px' : '0')};
  z-index: 10;
  max-height: 250px;
  -webkit-box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
  -moz-box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
  box-shadow: 3px 15px 19px -10px rgb(0 0 0 / 75%);
`

const ContainerChips = styled.div`
  width: 100%;
  max-width: 380px;
  min-height: 30px;
  height: 100%;
  border-radius: 3px;
  display: flex;
  padding: 7px 25px 7px 8px;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.palette.danger.main}` : `1px solid ${theme.palette.neutral.dark}`};
`
const Chip = styled.div`
  width: fit-content;
  padding: 3px 5px;
  border-radius: 5px;
  color: ${({ color = '#fff' }) => color};
  background-color: ${({ bgColor = '#212121' }) => bgColor};
`

const Item = styled.li`
  display: flex;
  cursor: pointer;
  padding: 7px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 30px;
  overflow: hidden;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

const Icon = styled.div`
  position: absolute;
  top: ${({ label }) => (label ? '25px' : '7px')};
  right: 10px;
  transition: all 0.5s ease;
  transform: ${({ opened }) => `rotate(${opened ? '0deg' : '180deg'})`};
`
export default MultiSelect
