import { createContext, useState } from 'react'
import { removeUserToken } from 'providers'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({})

  const userLogout = () => {
    removeUserToken()
    setAuth(null)
  }

  return <AuthContext.Provider value={{ auth, setAuth, userLogout }}>{children}</AuthContext.Provider>
}

export default AuthContext
