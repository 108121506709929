import { useState } from 'react'

import FormLogin from './FormLogin'
import FormResetPassword from './FormResetPassword'
import MarketingCard from './Marketing'

import { Row, Column } from 'components'

const Login = () => {
  const [option, setOption] = useState(true)

  return (
    <Row variant='center' padding='10px' height='100%' minHeight='100vh' backgroundColor='primary.50'>
      <Row
        width='100%'
        maxWidth='1240px'
        height='100%'
        maxHeight={{ mobile: '100%', tablet: '600px', desktop: '600px' }}
        minHeight='600px'
        borderRadius='10px'
        backgroundColor='white'
        overflow='hidden'
        flexDirection={{
          mobile: 'column',
          tablet: 'row',
          desktop: 'row'
        }}
      >
        <Column variant='center' width={queryColumn} height='100%' padding='30px' minHeight='600px' maxHeight='600px'>
          {option ? <FormLogin setOption={setOption} /> : <FormResetPassword setOption={setOption} />}
        </Column>
        <Column
          variant='center'
          width={queryColumn}
          height='100%'
          padding='15px'
          minHeight='600px'
          maxHeight='600px'
          sx={{
            backgroundImage: 'linear-gradient(25.48deg, rgb(210, 205, 232) -6.4%, rgba(210, 205, 232, 0) 107.81%)'
          }}
        >
          <MarketingCard />
        </Column>
      </Row>
    </Row>
  )
}

const queryColumn = { mobile: '100%', tablet: '50%', desktop: '50%' }

export default Login
