import { useMemo } from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { Button, Text, Table, Row } from 'components'

const ConflictCpfModal = ({ list, ...rest }) => {
  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id'
      },
      {
        header: 'Nome',
        accessorKey: 'nome_completo'
      },
      {
        header: 'E-mail',
        accessorKey: 'email'
      },
      {
        header: 'Ações',
        accessorKey: 'actions',
        sorting: false,
        cell: () => (
          <Row>
            <Button minWidth='32px' width='22px' variant='contained' color='secondary'>
              <VisibilityIcon fontSize='22px' />
            </Button>
          </Row>
        )
      }
    ],
    []
  )
  return (
    <Dialog {...rest}>
      <DialogTitle>
        <Text size='paragraph_1' color='primary' fontWeight='bold'>
          Este(s) está(ão) cadastrado(s) com este cpf
        </Text>
      </DialogTitle>
      <DialogContent>
        <Table data={list} columns={columns} emptyMessage='Ops... não existe conflito entre cpf.' />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={rest.onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConflictCpfModal
