import styled from 'styled-components'
import { format } from 'date-fns'

import Text from 'components/Text'
import Calendar from 'components/Calendar'
import { Input } from 'components/Input'

import { useClickout } from 'hooks'

const DatePicker = ({
  open = false,
  onClose = () => undefined,
  formater = 'dd/MM/yyyy',
  value = new Date(),
  onChange = () => undefined,
  input,
  inputProps,
  ...rest
}) => {
  const { elementRef } = useClickout(false, onClose, false)

  const handleChangeDate = date => {
    onClose(false)
    onChange(date)
  }

  return (
    <Base>
      <DateContainer>
        {input ? (
          <Input
            onClick={() => onClose(!open)}
            value={format(value, formater)}
            onChange={date => handleChangeDate(date)}
            readOnly
            {...inputProps}
          />
        ) : (
          <Text onClick={() => onClose(!open)} cursorPointer {...inputProps}>
            {format(value, formater)}
          </Text>
        )}
      </DateContainer>
      {open && (
        <CalendarContainer ref={elementRef}>
          <Calendar value={value} onChange={date => handleChangeDate(date)} color='secondary' {...rest} />
        </CalendarContainer>
      )}
    </Base>
  )
}

const Base = styled.div`
  position: relative;
  width: fit-content;
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CalendarContainer = styled.div`
  position: absolute;
  width: fit-content;
  z-index: 10;
  width: 100%;
  left: -45%;
  transform: translateX(-25px);
  top: 30px;
  border-radius: 10px;
  background-color: #fff;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  @media only screen and (min-width: 720px) {
    width: 500px;
  }
`

export default DatePicker
