import { Column, Switch, Select, Avatar, Spinner, Text, Row, Button } from 'components'

import { useUpdateUser } from 'hooks'

const MedicCard = ({
  loading,
  medicsRoomsList,
  handleGetEventsSchedule,
  handleGetMedicAndRooms,
  selectedSchedule,
  setSelectedSchedule
}) => {
  const { medicTotals } = useUpdateUser()

  const handleChangeSchedule = id => {
    const findSchedule = medicsRoomsList.find(item => item.id === id)
    setSelectedSchedule(findSchedule)
    handleGetMedicAndRooms({ id: findSchedule.id })
    handleGetEventsSchedule({
      id: findSchedule.id,
      type: findSchedule.tipo
    })
  }

  return (
    <Column
      width='100%'
      variant='center'
      padding='15px'
      borderRadius='5px'
      boxShadow='rgb(0 0 0 / 5%) 5px 5px 10px 5px;'
    >
      {loading ? (
        <Column width='100%' minHeight='300px' variant='center'>
          <Spinner variant='small' />
        </Column>
      ) : (
        <Column variant='center'>
          <Column>
            <Text>Multiagenda</Text>
            <Switch color='secondary' />
          </Column>
          <Avatar size='80px' src={selectedSchedule?.foto} name={selectedSchedule?.nome} />
          <Select
            margin='15px 0 0'
            options={medicsRoomsList}
            defaultValue={selectedSchedule?.nome}
            keys={{ label: 'nome', value: 'id' }}
            onChange={value => handleChangeSchedule(value)}
          />
          <Row variant='center' width='100%' gap='25px' mt='15px'>
            <Column width='70px' variant='center'>
              <Text>Agendados</Text>
              <Text>{medicTotals?.agendamentos}</Text>
            </Column>
            <Column width='70px' variant='center'>
              <Text>Finalizado</Text>
              <Text>{medicTotals?.finalizados}</Text>
            </Column>
          </Row>
          <Row variant='center' width='100%' gap='25px'>
            <Column width='70px' variant='center'>
              <Text>Retornos</Text>
              <Text>{medicTotals?.retornos}</Text>
            </Column>
            <Column width='70px' variant='center'>
              <Text>Cancelados</Text>
              <Text>{medicTotals?.cancelados}</Text>
            </Column>
          </Row>
          <Column width='100%' mt='20px'>
            <Button width='100%' variant='outlined' color='secondary'>
              Observações
            </Button>
          </Column>
          <Column width='100%' mt='10px'>
            <Button width='100%' variant='outlined' color='secondary'>
              Relatório de agendamentos
            </Button>
          </Column>
        </Column>
      )}
    </Column>
  )
}

export default MedicCard
