import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Navbar, Row, Column, Sidebar, PageFooter } from 'components'

import { useAuth } from 'hooks'
import { openTotals } from 'services'

const Layout = () => {
  const { auth } = useAuth()
  const location = useLocation()

  const { isLoading, data: totals } = useQuery('totals', openTotals)

  return auth?.token ? (
    <>
      <Navbar totals={isLoading || !totals ? {} : totals?.data} />
      <Row variant='start-start' overflow='hidden'>
        <Column variant='start-start'>
          <Sidebar totals={isLoading || !totals ? {} : totals?.data} />
        </Column>
        <Column
          variant='start-start'
          width='100%'
          padding={{
            mobile: '420px 20px 30px 110px',
            tablet: '210px 20px 30px 110px',
            desktop: '110px 20px 30px 110px'
          }}
        >
          <Outlet />
          <PageFooter />
        </Column>
      </Row>
    </>
  ) : (
    <Navigate to='/' state={{ from: location }} replace />
  )
}

export default Layout
