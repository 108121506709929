import { AnimatedContainer, PageHeader, UnderConstruction } from 'components'

const Contacts = () => {
  return (
    <AnimatedContainer>
      <PageHeader title='Contatos' />
      <UnderConstruction />
    </AnimatedContainer>
  )
}

export default Contacts
