import { forwardRef } from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import Text from '../Text'
import Row from '../Row'

const Input = forwardRef(
  (
    {
      label,
      mask,
      maxWidth = '280px',
      error,
      errorMessage,
      helperMessage,
      helperType = 'success',
      labelProps,
      margin,
      required,
      icon,
      height,
      onLeave,
      ...rest
    },
    ref
  ) => {
    return (
      <Base maxWidth={maxWidth} margin={margin} height={height}>
        {label && (
          <Text size='caption' color={error ? 'danger.main' : 'neutral.400'} {...labelProps}>
            {label}
            {required && (
              <Text component='span' marginLeft='3px' size='caption' color='danger.main'>
                *
              </Text>
            )}
          </Text>
        )}
        {mask ? (
          <StyledInputMasked ref={ref} error={error} mask={mask} {...rest} onBlur={onLeave}>
            {inputProps => <StyledInput {...inputProps} />}
          </StyledInputMasked>
        ) : (
          <StyledInput ref={ref} error={error} onBlur={onLeave} {...rest} />
        )}
        {error && (
          <Row variant='start-center' marginTop='3px' gap='3px'>
            <ErrorOutlineIcon fontSize='8px' color='danger' />
            <Text size='helper' marginTop='2px' color='danger.main'>
              {errorMessage || 'Campo obrigatório'}
            </Text>
          </Row>
        )}
        {helperMessage && (
          <Row variant='start-center' marginTop='3px' gap='3px'>
            <ErrorOutlineIcon fontSize='8px' color={helperType} />
            <Text size='helper' marginTop='2px' color={`${helperType}.main`}>
              {helperMessage || 'Mensagem de apoio'}
            </Text>
          </Row>
        )}
      </Base>
    )
  }
)

const Base = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: ${({ height = 'auto' }) => height};
  margin: ${({ margin = '5px 0' }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
`
const StyledInputMasked = styled(InputMask)`
  border-radius: 3px;
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.palette.danger.main}` : `1px solid ${theme.palette.neutral.dark}`};
  padding: ${({ padding = '7px 8px' }) => padding};
`

const StyledInput = styled.input`
  border-radius: 3px;
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.palette.danger.main}` : `1px solid ${theme.palette.neutral.dark}`};
  padding: ${({ padding = '7px 8px' }) => padding};
  color: ${({ color = '#212121', theme }) => theme?.palette[color]?.main || color};
`

export default Input
