const Stethoscope = ({ width = 24, height = 24, fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M12.4816 4.99955C11.7691 5.00997 11.1836 5.59125 11.169 6.30379C11.1586 6.81007 11.4295 7.25384 11.8357 7.48719V9.83315C11.8357 11.027 10.7898 11.9999 9.50227 11.9999C8.2522 11.9999 7.22714 11.0811 7.17089 9.93315C8.68764 9.62064 9.83353 8.27473 9.83353 6.66631V3.42863C9.83353 3.19112 9.66478 2.98486 9.43143 2.93902L8.12094 2.67651C7.85009 2.62234 7.58758 2.79735 7.53341 3.06819L7.46674 3.3953C7.41257 3.66614 7.58758 3.92866 7.85843 3.98283L8.49804 4.10992V6.63922C8.49804 7.74137 7.61883 8.65392 6.51669 8.66433C5.40413 8.67475 4.49783 7.7747 4.49783 6.66422V4.112L5.13744 3.98491C5.40829 3.93074 5.5833 3.66823 5.52913 3.39738L5.46455 3.07028C5.41038 2.79943 5.14786 2.62442 4.87701 2.67859L3.56861 2.93694C3.33526 2.98486 3.1665 3.18903 3.1665 3.42863V6.66631C3.1665 8.27681 4.31448 9.6248 5.8354 9.93315C5.88957 11.8166 7.51257 13.3333 9.50018 13.3333C11.5211 13.3333 13.167 11.7624 13.167 9.83315V7.48719C13.565 7.25592 13.8338 6.82673 13.8338 6.33296C13.8338 5.58917 13.2254 4.98913 12.4816 4.99955ZM12.5003 6.66631C12.317 6.66631 12.167 6.5163 12.167 6.33296C12.167 6.14961 12.317 5.9996 12.5003 5.9996C12.6837 5.9996 12.8337 6.14961 12.8337 6.33296C12.8337 6.5163 12.6837 6.66631 12.5003 6.66631Z'
      fill={fill}
    />
  </svg>
)

export default Stethoscope
