const directionDict = {
  top: 'rotate(90deg)',
  left: 'rotate(0deg)',
  right: 'rotate(180deg)',
  bottom: 'rotate(-90deg)'
}

const ArrowNavigate = ({ width = 8, height = 12, fill = '#565656', direction = 'left', cursorPointer, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 8 12'
    fill='none'
    style={{ transform: directionDict[direction], cursor: cursorPointer ? 'pointer' : 'normal' }}
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M0.140406 5.68175C-0.0468016 5.86896 -0.0468016 6.13105 0.140406 6.31825L5.60686 11.8596C5.79407 12.0468 6.0936 12.0468 6.24337 11.8596L6.9922 11.1108C7.17941 10.9236 7.17941 10.6615 6.9922 10.4743L2.5741 5.98128L6.9922 1.52574C7.17941 1.33853 7.17941 1.039 6.9922 0.889237L6.24337 0.140407C6.0936 -0.0468013 5.79407 -0.0468013 5.60686 0.140407L0.140406 5.68175Z'
      fill={fill}
    />
  </svg>
)

export default ArrowNavigate
