import { Column } from 'components'

const Card = ({ children }) => (
  <Column
    variant='start-start'
    margin='15px 0'
    padding='0'
    borderRadius='8px'
    sx={{ boxShadow: 'rgb(0 0 0 / 5%) 5px 5px 10px 5px !important' }}
  >
    {children}
  </Column>
)

export default Card
