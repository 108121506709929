const FiltroIcon = ({ width = 24, height = 24, fill = '#606060', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M18.4853 3.5H5.51654C4.17279 3.5 3.48529 5.125 4.45404 6.0625L9.51654 11.125V16.5C9.51654 17 9.73529 17.4375 10.1103 17.75L12.1103 19.25C13.079 19.9062 14.5165 19.2812 14.5165 18.0312V11.125L19.5478 6.0625C20.5165 5.125 19.829 3.5 18.4853 3.5ZM13.0165 10.5V18L11.0165 16.5V10.5L5.51654 5H18.5165L13.0165 10.5Z'
      fill={fill}
    />
  </svg>
)

export default FiltroIcon
