import { Routes as RoutesDom, Route } from 'react-router-dom'

import {
  GlobalImports,
  Layout,
  Login,
  Dashboard,
  PatientsList,
  BirthDays,
  Ranking,
  Contacts,
  Schedule,
  ServiceDashboard,
  Budgets,
  HealthPlan,
  Tiss,
  Vaccines,
  PatientOrigin,
  Campaign,
  EmailConfig,
  EmailFlow,
  NetPromoterScore,
  Opportunities,
  WhatsappMarketing,
  Transactions,
  FinancialConfig,
  Reports,
  Stock,
  TransferResources,
  Transfers,
  AttendanceReport
} from 'pages'

const Routes = () => (
  <RoutesDom>
    {/* public */}
    <Route path='/' element={<GlobalImports />}>
      <Route path='/' element={<Login />} />

      {/* Private */}
      <Route element={<Layout />}>
        <Route path='dashboard' element={<Dashboard />} />

        {/* Pacientes */}
        <Route path='pacientes/todos-pacientes' element={<PatientsList />} />
        <Route path='pacientes/aniversarios' element={<BirthDays />} />
        <Route path='pacientes/ranking' element={<Ranking />} />
        <Route path='pacientes/contatos' element={<Contacts />} />

        {/* Agenda */}
        <Route path='agenda' element={<Schedule />} />

        {/* Serviços */}
        <Route path='servicos/dashboard' element={<ServiceDashboard />} />
        <Route path='servicos/relatorios' element={<AttendanceReport />} />
        <Route path='servicos/orcamentos' element={<Budgets />} />
        <Route path='servicos/convenios' element={<HealthPlan />} />
        <Route path='servicos/tiss' element={<Tiss />} />
        <Route path='servicos/vacinas' element={<Vaccines />} />

        {/* Marketing */}
        <Route path='marketing/origens' element={<PatientOrigin />} />
        <Route path='marketing/marketing-configuracao' element={<EmailConfig />} />
        <Route path='marketing/email' element={<EmailFlow />} />
        <Route path='marketing/campanhas' element={<Campaign />} />
        <Route path='marketing/oportunidades' element={<Opportunities />} />
        <Route path='marketing/nps' element={<NetPromoterScore />} />
        <Route path='marketing/whatsapp' element={<WhatsappMarketing />} />

        {/* Financeiro */}
        <Route path='financeiro/transacoes' element={<Transactions />} />
        <Route path='financeiro/relatorios' element={<Reports />} />
        <Route path='financeiro/transferencias' element={<Transfers />} />
        <Route path='financeiro/repasses' element={<TransferResources />} />
        <Route path='financeiro/estoque' element={<Stock />} />
        <Route path='financeiro/financeiro-configuracao' element={<FinancialConfig />} />
      </Route>

      {/* 404 */}
      <Route path='*' element={<div>Page 404</div>} />
    </Route>
  </RoutesDom>
)

export default Routes
