const PauseIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 106.67 128'>
    <g>
      <path
        fill='#e0e0e0'
        d='M90.67,0h-10.67c-8.84,0-16,7.16-16,16V112c0,8.84,7.16,16,16,16h10.67c8.84,0,16-7.16,16-16V16c0-8.84-7.16-16-16-16ZM26.67,0h-10.67C7.16,0,0,7.16,0,16V112c0,8.84,7.16,16,16,16h10.67c8.84,0,16-7.16,16-16V16C42.67,7.16,35.5,0,26.67,0Z'
      />
    </g>
  </svg>
)

export default PauseIcon
