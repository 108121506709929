import styled from 'styled-components'

import { theme } from 'configs/theme'

import { Text } from 'components'

import { primarySymbol } from 'assets'

const Spinner = ({ variant = 'big', ...rest }) => {
  return (
    <Wrapper {...rest}>
      <BaseContainer variant={variant}>
        {variant === 'big' && (
          <LogoContainer>
            <ImgLogo src={primarySymbol} alt='GestãoDS' />
          </LogoContainer>
        )}
        <Base variant={variant} color={theme.palette.primary.main} speed='1.2s' />
        <Base variant={variant} color={theme.palette.primary.dark} speed='1.9s' />
        <Base variant={variant} color={theme.palette.primary.main} speed='2.5s' />
        <Base variant={variant} color={theme.palette.primary.main} speed='3.8s' />
        <Base variant={variant} color={theme.palette.primary.dark} speed='2.7s' />
        <Base variant={variant} color={theme.palette.primary.dark} speed='3.9s' />
      </BaseContainer>

      {variant === 'big' && (
        <PointerContainer>
          <Text textAlign='center' size='caption' color='#510972' fontWeight={300}>
            Carregando
          </Text>
          <PointContent>
            <Point delay='0.2s'>.</Point>
            <Point delay='0.3s'>.</Point>
            <Point delay='0.4s'>.</Point>
          </PointContent>
        </PointerContainer>
      )}
    </Wrapper>
  )
}

const variants = {
  big: {
    containerWidth: '150px',
    containerHeight: '135px',
    width: '100px',
    height: '100px',
    top: '20px',
    left: '20px'
  },
  small: { containerWidth: '100px', containerHeight: '85px', width: '50px', height: '50px', top: '20px', left: '20px' },
  loader: {
    containerWidth: '15px',
    containerHeight: '15px',
    width: '15px',
    height: '15px',
    top: '0px',
    left: '0px'
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-width: ${({ minWidth = '0' }) => minWidth};
  min-height: ${({ minHeight = '0' }) => minHeight};
`

const BaseContainer = styled.div`
  position: relative;
  width: ${({ variant }) => variants[variant].containerWidth};
  height: ${({ variant }) => variants[variant].containerHeight};
`
const Base = styled.div`
  position: absolute;
  top: ${({ variant }) => variants[variant].top};
  left: ${({ variant }) => variants[variant].left};
  width: ${({ variant }) => variants[variant].width};
  height: ${({ variant }) => variants[variant].height};
  border-radius: 50%;
  border-top: ${({ color, variant }) => `${variant === 'big' ? '3px' : '2px'} solid ${color}`};
  animation: ${({ speed }) => `spinnerLoader ${speed} infinite ease`};
  @keyframes spinnerLoader {
    to {
      transform: rotate(360deg);
    }
  }
`
const PointerContainer = styled.div`
  position: relative;
  width: 92px;
  display: flex;
  margin-left: 5px;
`
const PointContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20px;
  margin-left: 2px;
`

const Point = styled.p`
  color: #761b9d;
  margin: -7px 1px 0;
  animation: pointerUp 1s infinite ease;
  animation-delay: ${({ delay }) => delay};
  @keyframes pointerUp {
    from {
      transform: translateY(-3px);
    }
    50% {
      transform: translateY(3px);
    }
    to {
      transform: translateY(-3px);
    }
  }
`

const LogoContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
`

const ImgLogo = styled.img`
  width: 60px;
  height: 60px;
`

export default Spinner
