import { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material'

import { Button, Text, Row, Input, Select, Column } from 'components'

const HealthPlanAdd = ({
  healthPlansList,
  setPatientPlansList,
  patientPlansList,
  editData = {
    operadora: '',
    numero_cartao: '',
    data_validade: '',
    codigo_cns: ''
  },
  ...rest
}) => {
  const [fields, setFields] = useState({})

  const handleAddPlan = () => {
    const filterList = patientPlansList.filter(item => item.operadora !== fields.operadora)

    editData?.operadora !== ''
      ? setPatientPlansList([...filterList, fields])
      : setPatientPlansList([...patientPlansList, fields])

    setFields({
      operadora: '',
      numero_cartao: '',
      data_validade: '',
      codigo_cns: ''
    })
    rest.onClose()
  }

  useEffect(() => {
    if (editData) {
      setFields(editData)
    }
  }, [editData])

  return (
    <Dialog {...rest}>
      <DialogTitle>
        <Text size='paragraph_1' color='primary' fontWeight='bold'>
          Adicione novo convênio ao paciente
        </Text>
      </DialogTitle>
      <DialogContent sx={{ height: '330px' }}>
        <Column>
          <Row gap='15px'>
            <Select
              maxWidth='190px'
              options={healthPlansList}
              keys={{ value: 'id', label: 'nome' }}
              label='Convênio'
              {...(editData?.operadora
                ? { value: healthPlansList.find(item => item.id === fields?.operadora)?.nome || '' }
                : '')}
              onChange={value => setFields({ ...fields, operadora: value })}
              disabled={editData?.operadora}
            />
            <Input
              maxWidth='200px'
              label='Número do cartão'
              value={fields?.numero_cartao}
              onChange={e => setFields({ ...fields, numero_cartao: e.target.value })}
            />
          </Row>
          <Row gap='15px'>
            <Input
              maxWidth='200px'
              mask='99/99/9999'
              label='Data de validade'
              value={fields?.data_validade}
              onChange={e => setFields({ ...fields, data_validade: e.target.value })}
            />
            <Input
              maxWidth='200px'
              label='Cartão nascional de saúde'
              value={fields?.codigo_cns}
              onChange={e => setFields({ ...fields, codigo_cns: e.target.value })}
            />
          </Row>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant='ghost' onClick={rest.onClose}>
          Fechar
        </Button>
        <Button variant='contained' onClick={handleAddPlan}>
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HealthPlanAdd
