import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Input, Column, Row, Select, Checkbox, SelectAsync, TextArea, Avatar } from 'components'
import ConflictCpfModal from './Modals/ConflictCpfModal'

import { getOcupationList, verifyCpfPatient } from 'services'

import { removeMask } from 'helpers'

import { bloodTypeOptions, civilStateOptions, genderOptions } from 'configs'

const BasicInfo = () => {
  const [loading, setLoading] = useState(false)
  const [validCpf, setValidCpf] = useState(false)
  const [conflictCpfList, setConflictCpfList] = useState(null)

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors
  } = useFormContext()

  const checkPatientCpf = async value => {
    try {
      setLoading(true)
      setValidCpf(false)
      const formatedValue = removeMask(value)
      if (formatedValue?.length < 11) {
        setError('cpf', { type: 'custom', message: 'CPF inválido!' })
        return false
      }

      clearErrors('cpf')

      const response = await verifyCpfPatient(formatedValue)

      if (response.data.count > 0) {
        setError('cpf', { type: 'custom', message: 'Este cpf já está cadastrado.' })
        setConflictCpfList(response.data.results)
        return false
      }

      setValidCpf(true)
    } catch {
      toast.error('Não foi possível verificar cpf do paciente.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Column variant='start start' padding='15px 0' maxHeight='500px' overflow='auto'>
      <Row gap='40px' flexWrap='wrap'>
        <Avatar
          margin='10px 0'
          defaultValue={getValues('imagem')}
          upload
          uploadState={value => setValue('imagem', value)}
        />
        <Column>
          <Input error={errors?.nome_completo} label='Nome completo' {...register('nome_completo')} />
          <Input label='Como gostaria de ser chamado(a)?' {...register('apelido')} />
        </Column>
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Input
          maxWidth='150px'
          mask='99/99/9999'
          label='Nascimento'
          error={errors?.data_nascimento}
          {...register('nascimento')}
        />
        <Input maxWidth='250px' label='RG' error={errors?.rg} {...register('rg')} />
        <Input
          maxWidth='250px'
          mask='999.999.999-99'
          onLeave={e => checkPatientCpf(e.target.value)}
          label='CPF'
          error={errors?.cpf}
          errorMessage={errors?.cpf?.message}
          helperMessage={validCpf && 'CPF livre'}
          disabled={loading}
          {...register('cpf')}
        />
        <Select
          maxWidth='150px'
          label='Tipo sanguíneo'
          options={bloodTypeOptions}
          defaultValue={getValues('tipo_sanguineo')}
          onChange={value => setValue('tipo_sanguineo', value)}
        />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Input maxWidth='250px' label='Nome do cônjuge' error={errors?.nome_conjuge} {...register('nome_conjuge')} />
        <Input
          maxWidth='250px'
          label='Nome do pai'
          error={errors?.nome_completo_pai}
          {...register('nome_completo_pai')}
        />
        <Input
          maxWidth='250px'
          label='Nome do mãe'
          error={errors?.nome_completo_mae}
          {...register('nome_completo_mae')}
        />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Select
          maxWidth='150px'
          label='Gênero'
          options={genderOptions}
          defaultValue={genderOptions.find(item => item.value === getValues('sexo'))?.label || ''}
          onChange={value => setValue('sexo', value)}
        />
        <Select
          maxWidth='150px'
          label='Estado civil'
          options={civilStateOptions}
          defaultValue={getValues('estado_civil')}
          onChange={value => setValue('estado_civil', value)}
        />
        <Input
          maxWidth='150px'
          height='auto'
          margin='5px 0 10px'
          mask='99/99/9999'
          label='Informar óbito'
          error={errors?.data_obito}
          {...register('data_obito')}
        />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <SelectAsync
          label='Ocupação'
          fetchAction={getOcupationList}
          keys={{ label: 'descricao', value: 'id' }}
          defaultValue={getValues('ocupacao')}
          onChange={value => setValue('ocupacao', value)}
        />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <Checkbox label='Tem filhos?' defaultValue={getValues('tem_filhos')} {...register('tem_filhos')} />
      </Row>
      <Row gap='15px' flexWrap='wrap'>
        <TextArea label='Observação:' minHeight='150px' register={register('obs')} />
      </Row>
      {conflictCpfList && (
        <ConflictCpfModal open={conflictCpfList} onClose={() => setConflictCpfList(null)} list={conflictCpfList} />
      )}
    </Column>
  )
}
export default BasicInfo
