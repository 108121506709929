import { useState } from 'react'
import styled from 'styled-components'

import Header from './Header'

const Navbar = ({ totals }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <Container>
      <Header totals={totals} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
`

export default Navbar
