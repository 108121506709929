import styled from 'styled-components'
import { Column, Text } from 'components'

import { error } from 'assets'

const EmptyPage = ({ title, text = ' Caso o erro persista entre em contato com nosso support.', ...rest }) => (
  <Column variant='center' {...rest}>
    {title && (
      <Text size='h5' color='neutral.500' maxWidth='700px' textAlign='center'>
        {title}
      </Text>
    )}
    <Image src={error} alt='Error' />
    <Text size='h6' color='secondary' maxWidth='650px' textAlign='center'>
      {text}
    </Text>
  </Column>
)

const Image = styled.img`
  width: 60px;
  margin: 25px 0;
`

export default EmptyPage
