const HeartBeat = ({ width = '17', height = '16', fill = '#656565', ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M9.83727 7.74664L8.80192 9.81736C8.67692 10.0694 8.31444 10.0611 8.19987 9.80486L7.01452 7.17375L6.38955 8.66742H4.42924L8.23111 12.5526C8.37902 12.7047 8.61859 12.7047 8.7665 12.5526L12.5705 8.66742H10.2977L9.83727 7.74664ZM13.035 4.20725L12.985 4.15517C11.9122 3.0594 10.156 3.0594 9.08107 4.15517L8.49985 4.75097L7.91863 4.15726C6.84577 3.0594 5.08754 3.0594 4.01468 4.15726L3.96469 4.20725C2.95016 5.24469 2.90641 6.89669 3.81261 8.00079H5.94582L6.6937 6.20506C6.80619 5.93632 7.18534 5.93007 7.30617 6.19672L8.5186 8.89032L9.53937 6.85086C9.66228 6.60504 10.0123 6.60504 10.1352 6.85086L10.7101 8.00079H13.1871C14.0933 6.89669 14.0495 5.24469 13.035 4.20725Z'
      fill={fill}
    />
  </svg>
)

export default HeartBeat
