const UsdCircle = ({ width = 32, height = 32, fill, active, ...rest }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <path
      d='M13.3438 11.625L11.0938 10.9688C10.8125 10.875 10.5938 10.5625 10.5938 10.25C10.5938 9.84375 10.9062 9.5 11.25 9.5H12.6562C12.875 9.5 13.0938 9.5625 13.2812 9.6875C13.4688 9.8125 13.7188 9.78125 13.875 9.625L14.25 9.28125C14.5 9.0625 14.4688 8.6875 14.1875 8.5C13.75 8.1875 13.25 8.03125 12.75 8.03125V7.5C12.75 7.25 12.5312 7 12.25 7H11.75C11.4688 7 11.25 7.25 11.25 7.5V8C10.0625 8.03125 9.125 9.03125 9.125 10.25C9.125 11.2188 9.75 12.125 10.6562 12.375L12.9062 13.0625C13.1875 13.1562 13.375 13.4375 13.375 13.7812C13.375 14.1875 13.0938 14.5 12.7188 14.5H11.3125C11.0938 14.5 10.9062 14.4688 10.7188 14.3438C10.5 14.2188 10.25 14.25 10.0938 14.4062L9.71875 14.75C9.46875 14.9688 9.53125 15.3438 9.78125 15.5312C10.2188 15.8125 10.7188 16 11.25 16V16.5C11.25 16.7812 11.4688 17 11.75 17H12.25C12.5 17 12.75 16.7812 12.75 16.5V16C13.9062 16 14.875 15 14.875 13.7812C14.875 12.8125 14.25 11.9062 13.3438 11.625ZM12 4.25C7.71875 4.25 4.25 7.71875 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.2812 19.75 19.75 16.2812 19.75 12C19.75 7.71875 16.2812 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25Z'
      fill={active ? '#743A8E' : fill ? fill : '#2C2C2C'}
    />
  </svg>
)

export default UsdCircle
